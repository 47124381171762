import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BrukningsenhetModel } from '../../../models/Models';
import { ClientService } from '../../../client.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';

@Component({
  selector: 'dv-trimble-field',
  templateUrl: './trimble-field.component.html',
  styleUrls: ['./trimble-field.component.scss'],
})
export class TrimbleFieldComponent implements OnInit {
  currStepp: number = undefined;
  selectedBrukningsenheter: BrukningsenhetModel[] = [];
  year = 0;

  constructor(
    private clientService: ClientService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }

  ngOnInit() { }

  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
    this.cd.detectChanges();
  }

  selectedYear(ev) {
    this.year = ev;
  }

  export() {
    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.uploadSkifte(
        this.year,
        this.selectedBrukningsenheter.map((b) => b.id),
        'trimble-display'
      ),
      disableClose: true,
    });
  }

  goToStart() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.router.navigate(['client', params['clientid']]);
    });
  }
}

<dv-wizard #wizard [activeStep]="currStepp">
  <dv-wizardstep [title]="'_common_agricultural_units' | dvt">
    <div class="stepwrap">
      <dv-brukenhet (selectedBruk)="selectedBruk($event)"></dv-brukenhet>
    </div>
    <mat-card>
      <button 
        class="float-right" 
        [disabled]="selectedBrukningsenheter.length === 0" 
        mat-raised-button 
        (click)="wizard.activeStep = 2" 
        color="primary"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button 
        class="float-right" 
        mat-raised-button (click)="goToStart()"
      >
        <mat-icon>arrow_left</mat-icon>
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_year' | dvt">
    <div class="stepwrap">
      <dv-year (selectedYear)="selectedYear($event)"></dv-year>
    </div>
    <mat-card>
      <button 
        class="float-right" 
        mat-raised-button 
        (click)="wizard.activeStep = 3" 
        color="primary" 
        [disabled]="year === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button 
        class="float-right" 
        mat-raised-button 
        (click)="wizard.activeStep = 2"
      >
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_parcels' | dvt">
    <div class="stepwrap">
      <dv-skifte 
        (selectedSkiften)="selectedSkifteId = $event" 
        [eu]="true" [brukenheter]="selectedBrukningsenheter" 
        [year]="year"
      >
    </dv-skifte>
    </div>
    <mat-card>
      <button 
        class="float-right" 
        mat-raised-button 
        (click)="wizard.activeStep = 4" 
        color="primary" 
        [disabled]="year === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button 
        class="float-right" 
        mat-raised-button 
        (click)="wizard.activeStep = 2"
      >
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_export' | dvt">
    <div class="stepwrap">
      <h2 class="text-center">{{ "_common_soon_done" | dvt }}</h2>
      <div class="export-buttons">
        <div>
          <button 
            mat-raised-button 
            (click)="export()"
          >
            {{ "_common_download" | dvt }}
          </button>
        </div>
      </div>
    </div>
    <mat-card>
      <button 
        class="float-right" 
        mat-raised-button 
        (click)="goToStart()"
      >
        <mat-icon>arrow_left</mat-icon>
        {{ "_common_back_to_start" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
</dv-wizard>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-year',
  templateUrl: './year.component.html',
  styleUrls: ['./year.component.scss'],
})
export class YearComponent implements OnInit {
  years: number[] = [];

  @Output() selectedYear = new EventEmitter();

  constructor(
    private snackBar: MatSnackBar,
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private translateService: DvToolbarTranslateService
  ) {}

  ngOnInit() {
    this.activatedRoute.pathFromRoot[1].params.subscribe(
      (p) => {
        this.clientService.setClient(p['clientid']);
        this.clientService.getArForKlient().subscribe((years) => {
          this.years = years.sort((a, b) => b - a);
        });
      },
      (err) => {
        this.snackBar.open(err, this.translateService.t('Stäng'));
      }
    );
  }

  change(value: number) {
    this.selectedYear.emit(value);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dv-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
})
export class BlankComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

<dv-wizard #wizard [activeStep]="currStepp">
  <dv-wizardstep [title]="'_common_agricultural_units' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-brukenhet (selectedBruk)="selectedBruk($event)"></dv-brukenhet>
    </div>
    <mat-card>
      <button
        style="float: right"
        [disabled]="selectedBrukningsenheter.length === 0"
        mat-raised-button
        (click)="wizard.activeStep = 2"
        color="primary"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="goToStart()"><mat-icon>arrow_left</mat-icon></button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_year' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-year (selectedYear)="selectedYear($event)"></dv-year>
    </div>
    <mat-card>
      <button
        style="float: right"
        mat-raised-button
        (click)="wizard.activeStep = 3"
        color="primary"
        [disabled]="year === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="wizard.activeStep = 1">
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_parcels' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-skifte
        (selectedSkiften)="selectedSkifteId = $event"
        [eu]="false"
        [brukenheter]="selectedBrukningsenheter"
        [year]="year"
      ></dv-skifte>
    </div>
    <mat-card>
      <button
        style="float: right"
        mat-raised-button
        (click)="wizard.activeStep = 4"
        color="primary"
        [disabled]="year === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="wizard.activeStep = 2">
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_export' | dvt">
    <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; flex: auto">
      <h2 style="text-align: center">{{ "_common_file_soon_done" | dvt }}</h2>
      <div class="export-buttons" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="center">
        <div fxLayout="column" fxLayout.xs="row">
          <button mat-raised-button (click)="generateExcelfile()">{{ "_common_download" | dvt }}</button>
        </div>
      </div>
    </div>
    <mat-card>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>
        {{ "_common_back_to_start" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
</dv-wizard>

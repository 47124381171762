import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClientService } from '../../../client.service';
import { VodkaExportModel } from '../../../models/Models';
import { Receiver } from '../../receiver';

@Component({
  selector: 'dv-vodka-export',
  templateUrl: './vodka-export.component.html',
  styleUrls: ['./vodka-export.component.scss'],
})
export class VodkaExportComponent extends Receiver implements OnInit {
  currStepp = 0;
  year = 0;
  selectedBrukningsenheter: any[] = [];
  exportUrl = '';
  selectedSkifteId: number[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    activatedRoute: ActivatedRoute,
    router: Router,
    private clientService: ClientService
  ) {
    super(activatedRoute, router);
  }

  ngOnInit() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }

  selectedYear(year: number) {
    this.year = year;
  }

  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
    this.cd.detectChanges();
  }

  export() {
    const model: VodkaExportModel = <VodkaExportModel>{};
    model.skiften = this.selectedSkifteId;
    this.clientService.exportVodka(this.year, model).subscribe((res) => {
      this.exportUrl = res;
      (window as any).open(this.exportUrl, "_self");
      this.cd.detectChanges();
    });
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dv-download-file-status',
  templateUrl: './download-file-status.component.html',
  styleUrls: ['./download-file-status.component.scss'],
})
export class DownloadFileStatusComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: number) {}

  ngOnInit() {}
}

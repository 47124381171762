export class LegendModel {
  constructor() {}
  title = '';
  description = '';
  field = '';
  fileType = '';
  colorMode = 'Solid';
  colorScheme: ColorSchemeModel[] = [];
  colorScale = [];
  medelText = '';
  sumText = '';
  sum = 0;
  decimal = 1;
  classification = [];
  colorField: string;
  valueField: string;
}

export class ColorSchemeModel {
  constructor(
    public minValue,
    public maxValue,
    public minColor,
    public maxColor
  ) {}

  intervall = 0;
  intervallValue = true;
  intervallText = '';
  valueSum = 0;
  valueCount = 0;
  useHSLColor = false;
  areal = 0;
}

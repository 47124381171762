<mat-form-field>
  <mat-select (selectionChange)="change($event.value)" placeholder="{{ '_common_year' | dvt }}">
    <mat-option [value]="0">
      {{ "_common_select_year" | dvt }}
    </mat-option>
    <mat-option *ngFor="let year of years" [value]="year">
      {{ year }}
    </mat-option>
  </mat-select>
</mat-form-field>

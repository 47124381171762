<dv-wizard #wizard [activeStep]="currStepp">
  <dv-wizardstep [title]="'_common_agricultural_units' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-brukenhet (selectedBruk)="selectedBruk($event)"></dv-brukenhet>
    </div>
    <mat-card>
      <button
        style="float: right"
        [disabled]="selectedBrukningsenheter.length === 0"
        mat-raised-button
        (click)="wizard.activeStep = 2"
        color="primary"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="goToStart()"><mat-icon>arrow_left</mat-icon></button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_export' | dvt">
    <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; flex: auto">
      <h2 style="text-align: center">{{ "_common_file_soon_done" | dvt }}</h2>
      <p style="text-align: center">{{ "_export_deere_upload_to" | dvt }}</p>
      <button color="accent" mat-raised-button (click)="export('myjohndeere')">{{ "_common_upload" | dvt }}</button>
    </div>
    <mat-card>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>
        {{ "_common_back_to_start" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
</dv-wizard>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OauthDialogComponent } from './oauth-dialog.component';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class OAuthService {
  constructor(private dialog: MatDialog) {}

  login(uri: string, icon): Observable<boolean> {
    const sub = new Subject<boolean>();

    const dialogRes = this.dialog.open(OauthDialogComponent, {
      data: {
        uri: uri,
        sub: sub,
        icon: icon,
      },
      width: '300px',
      height: '200px',
    });

    return sub.asObservable();
  }
}

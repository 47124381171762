import {
  Component,
  Input,
  HostBinding,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'dv-wizardstep',
  template: '<ng-content></ng-content>',
  styles: [
    ':host { display: none; flex:auto; overflow:auto; flex-direction: column; flex-basis:0px; flex-shrink:0; }',
  ],
  host: { class: 'dv-background' },
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WizardStepComponent {
  constructor(private cd: ChangeDetectorRef) { }

  @Input() title: string;
  @Input() step: number;

  @HostBinding('style.display') visibleStyle = 'none';

  @Input()
  set current(value: boolean) {
    this.isCurrent = value;
    this.visibleStyle = value ? 'flex' : 'none';
    this.cd.markForCheck();
  }

  isCurrent: boolean;
  isDone: boolean;
  isLast: boolean;
}

<mat-dialog-content>
  <span>{{ "_common_access_denied_for_client" | dvt }}</span>
  <span>{{ "_common_how_to_proceed" | dvt }}</span>
</mat-dialog-content>
<mat-dialog-actions>
  <button style="margin-right: 10px" mat-raised-button color="primary" (click)="logOut()">
    {{ "_common_log_out" | dvt }}
  </button>
  <button mat-raised-button color="primary" (click)="changeClient()">{{ "_common_change_client" | dvt }}</button>
</mat-dialog-actions>

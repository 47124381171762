import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { UserFileModel } from '../../models/FileModel';
import { LegendModel } from '../../models/LegendModel';

@Component({
  selector: 'dv-map-legend',
  template: '<canvas #canvas width="300" height="125"></canvas>',
  styles: [
    ':host{color:#fff; position: absolute; right:20px; bottom:100px; background-color:rgba(0, 0, 0, 0.32); border-radius:4px; border:solid 2px #fff;}',
  ],
})
export class DvMapLegendComponent implements OnInit {
  constructor() {}

  private _legend: Legend;
  hasData = false;

  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.reDraw();
  }

  public setFile(value: UserFileModel) {
    this._legend.setData(value.legend);

    if (this._legend.horizontal) {
      this.canvas.nativeElement.width =
        this.canvas.nativeElement.offsetParent.offsetParent.clientWidth - 40;
      this._legend.draw();
    }
  }

  ngOnInit() {
    this._legend = new Legend(
      this.canvas.nativeElement,
      window.innerWidth < 500
    );
  }

  reDraw() {
    if (!this._legend) {
      return;
    }

    this._legend.setHorizontal(window.innerWidth < 500);

    if (this._legend.horizontal) {
      this.canvas.nativeElement.width =
        this.canvas.nativeElement.offsetParent.offsetParent.clientWidth - 40;
    }

    this._legend.draw();
  }
}

export class Legend {
  constructor(canvas: HTMLCanvasElement, horizontal?: boolean) {
    this.ctx = canvas.getContext('2d');
    this.setHorizontal(horizontal || false);
  }
  ctx: CanvasRenderingContext2D;
  widthOfColor = 55;
  margin = 10;
  fontLarge = '25px Arial Narrow';
  font = '14px Arial Narrow';
  fontSmall = '10px Arial Narrow';
  data: LegendModel = null;
  colorX = 55;
  fontX = 75;
  /**
   * This is the size of a color, with regard of the avalibel draw area and the number of colors
   */
  sizeOfColor = 0;
  title = null;
  decimal = 2;
  height = 0;
  horizontal: boolean;

  setHorizontal(horizontal) {
    this.horizontal = horizontal;
    this.ctx.canvas.width = this.horizontal ? 300 : 150;
    this.ctx.canvas.height = this.horizontal ? 125 : 300;
    this.height = this.ctx.canvas.height - this.margin * 2;
  }

  setData(data: LegendModel) {
    this.data = data;
    this.decimal = data.decimal;
    this.title = data.title;

    let lengthOfValue = 0;
    this.ctx.font = this.fontSmall;
    const ctx = this.ctx;

    this.colorX = 0;
    data.colorScheme.forEach(function (c) {
      if (c.intervallValue) {
        const w = ctx.measureText(c.minValue.toFixed(data.decimal)).width;
        if (w > lengthOfValue) {
          lengthOfValue = w;
        }
      }
    });

    this.fontX = lengthOfValue + 15;
    this.colorX = this.fontX + 5;

    this.draw();
  }

  drawColorRec(
    color: string,
    height: number,
    weight: number,
    txt: string,
    x: number,
    y: number
  ) {
    const colorSpace = 2;
    const baseColorSize = 15;
    const givaColorWidth = this.widthOfColor - baseColorSize - colorSpace;

    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    if (this.horizontal) {
      this.ctx.fillRect(x, y, height, baseColorSize);
    } else {
      this.ctx.fillRect(x, y, baseColorSize, height);
    }

    if (this.horizontal) {
      this.ctx.fillRect(
        x,
        y - weight * givaColorWidth - colorSpace,
        height,
        weight * givaColorWidth
      );
    } else {
      this.ctx.fillRect(
        x + baseColorSize + colorSpace,
        y,
        givaColorWidth * weight,
        height
      );
    }

    this.ctx.font = this.fontSmall;
    const txtSize = this.ctx.measureText(txt);
    this.ctx.textAlign = 'left';
    this.ctx.fillStyle = '#fff';
    if (this.horizontal) {
      this.ctx.fillText(
        txt,
        x + this.sizeOfColor / 2 - txtSize.width / 2,
        y - weight * givaColorWidth - 12
      );
    } else {
      this.ctx.fillText(
        txt,
        x + baseColorSize + colorSpace + givaColorWidth * weight + 4,
        y + this.sizeOfColor / 2 + 5
      );
    }
  }

  draw() {
    if (this.data === null || this.data === undefined) {
      return;
    }

    const maxAreal = Math.max.apply(
      null,
      this.data.colorScheme.map(function (d) {
        return d.areal;
      })
    );

    const sumAreal: number = this.data.colorScheme
      .map((a) => a.areal)
      .reduce(function (a, b) {
        return a + b;
      }, 0);

    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    this.ctx.fillStyle = 'rgb(255,255,255)';
    this.ctx.font = this.fontLarge;
    if (this.horizontal) {
      this.ctx.fillText(this.title, this.fontX, this.margin + 25);
    } else {
      this.ctx.fillText(
        this.title,
        (this.ctx.canvas.width - this.margin) / 2 -
          this.ctx.measureText(this.title).width / 2,
        this.margin + 20
      );
    }

    this.ctx.font = this.font;
    if (this.horizontal) {
      this.ctx.textAlign = 'right';
      this.ctx.fillText(
        this.data.medelText,
        this.ctx.canvas.width - this.margin,
        this.margin + 10
      );
      this.ctx.fillText(
        this.data.sumText,
        this.ctx.canvas.width - this.margin,
        this.margin + 28
      );
    } else {
      this.ctx.fillText(this.data.medelText, this.margin, this.margin + 40);
      this.ctx.fillText(this.data.sumText, this.margin, this.margin + 58);
    }

    const startOLegend = 70;

    if (this.horizontal) {
      this.sizeOfColor =
        (this.ctx.canvas.width - startOLegend) / this.data.colorScheme.length;
      this.widthOfColor = 40;
    } else {
      this.sizeOfColor =
        (this.height - startOLegend) / this.data.colorScheme.length;
      this.widthOfColor = 55;
    }

    for (let i = 0; i < this.data.colorScheme.length; i++) {
      const colorScheme = this.data.colorScheme[
        this.data.colorScheme.length - i - 1
      ];

      if (this.horizontal) {
        this.drawColorRec(
          colorScheme.minColor,
          this.sizeOfColor,
          colorScheme.areal / maxAreal,
          Math.round((colorScheme.areal / sumAreal) * 100) + '%',
          this.colorX + i * this.sizeOfColor,
          startOLegend + (this.height - startOLegend - 15)
        );
      } else {
        this.drawColorRec(
          colorScheme.minColor,
          this.sizeOfColor,
          colorScheme.areal / maxAreal,
          Math.round((colorScheme.areal / sumAreal) * 100) + '%',
          this.colorX,
          startOLegend + (this.margin + i * this.sizeOfColor)
        );
      }

      if (colorScheme.intervallValue && i > 0) {
        this.ctx.textAlign = 'left';
        const maxValueWidth =
          this.ctx.measureText(colorScheme.maxValue.toFixed(this.decimal))
            .width / 2;
        if (this.horizontal) {
          this.drawHelpText(
            colorScheme.maxValue.toFixed(this.decimal),
            this.colorX + (this.margin + i * this.sizeOfColor) - maxValueWidth,
            this.ctx.canvas.height - this.margin
          );
        } else {
          this.drawHelpText(
            colorScheme.maxValue.toFixed(this.decimal),
            this.fontX,
            startOLegend + (this.margin + i * this.sizeOfColor)
          );
        }
      }
    }
  }

  drawHelpText(text, x, y) {
    const align = this.ctx.textAlign;
    this.ctx.textAlign = 'right';
    this.ctx.fillStyle = 'rgb(255,255,255)';
    this.ctx.font = this.font;
    this.ctx.fillText(text, x, y + 7);
    this.ctx.textAlign = align;
  }
  getColorSchemeIndexFromValue(value: number) {
    if (value <= this.data.colorScheme[0].minValue) {
      return 0;
    }

    if (
      value >= this.data.colorScheme[this.data.colorScheme.length - 1].maxValue
    ) {
      return this.data.colorScheme.length - 1;
    }

    for (let i = 0; i < this.data.colorScheme.length; i++) {
      if (
        value >= this.data.colorScheme[i].minValue &&
        value <= this.data.colorScheme[i].maxValue
      ) {
        return i;
      }
    }
  }
}

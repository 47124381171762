import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../../client.service';
import { Receiver } from '../../receiver';
import {
  VodkaExportModel,
  FutureFarmExportModel,
} from '../../../models/Models';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dv-futurefarm-cropplan-export',
  templateUrl: './futurefarm-cropplan-export.component.html',
  styleUrls: ['./futurefarm-cropplan-export.component.scss'],
})
export class FuturefarmCropplanExportComponent
  extends Receiver
  implements OnInit {
  currStepp = 0;
  year = 0;
  selectedBrukningsenheter: any[] = [];
  exportUrl = '';
  selectedSkifteId: number[] = [];
  isSkira = window.location.href.indexOf('skira') !== -1;
  constructor(
    private dialog: MatDialog,
    activatedRoute: ActivatedRoute,
    router: Router,
    private clientService: ClientService,
    private cd: ChangeDetectorRef
  ) {
    super(activatedRoute, router);
  }

  ngOnInit() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }
  selectedYear(year: number) {
    this.year = year;
  }
  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
    this.cd.detectChanges();
  }
  export() {
    const model: FutureFarmExportModel = <FutureFarmExportModel>{};
    model.skiften = this.selectedSkifteId;



    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.exportFuturefarmCropplan(model, this.year),
      disableClose: true,
    });

  }
  exportSkira() {
    const model: FutureFarmExportModel = <FutureFarmExportModel>{};
    model.skiften = this.selectedSkifteId;



    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.exportSkiraCropplan(model, this.year),
      disableClose: true,
    });

  }
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Receiver } from '../receiver';
import { ClientService } from '../../client.service';
import {
  FutureFarmExportModel,
} from '../../models/Models';
import { DoneDialogComponent } from '../../common-components/done-dialog/done-dialog.component';

@Component({
  selector: 'dv-cropplan-export',
  templateUrl: './cropplan-export.component.html',
  styleUrls: ['./cropplan-export.component.scss'],
})
export class CropplanExportComponent
  extends Receiver
  implements OnInit {
  currStepp = 0;
  year = 0;
  selectedBrukningsenheter: any[] = [];
  exportUrl = '';
  selectedSkifteId: number[] = [];
  constructor(
    private dialog: MatDialog,
    activatedRoute: ActivatedRoute,
    router: Router,
    private clientService: ClientService,
    private cd: ChangeDetectorRef
  ) {
    super(activatedRoute, router);
  }

  ngOnInit() : void {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }
  selectedYear(year: number) : void {
    this.year = year;
  }
  selectedBruk(ev) : void {
    this.selectedBrukningsenheter = ev;
    this.cd.detectChanges();
  }
  export() : void {
    const model: FutureFarmExportModel = <FutureFarmExportModel>{};
    model.skiften = this.selectedSkifteId;



    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.exportCropplan(model, this.year),
      disableClose: true,
    });

  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-oauth-dialog',
  templateUrl: './oauth-dialog.component.html',
  styleUrls: ['./oauth-dialog.component.scss'],
})
export class OauthDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<OauthDialogComponent>,
    private translateService: DvToolbarTranslateService
  ) {}

  ngOnInit() {}
  close() {
    this.data.sub.error(this.translateService.t('Inloggning avbröts'));
    this.ref.close();
  }

  auth() {
    const win = window.open(this.data.uri, '_blank');
    win.postMessage('isloaded', '*');
    win.focus();

    window.addEventListener(
      'message',
      (ev) => {
        win.close();
        this.ref.close();
        this.data.sub.next(true);
        this.data.sub.complete();
      },
      false
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseReceiver } from '../base-receiver';
import { ExportType } from '../../models/ExportTypes';

@Component({
  selector: 'dv-sam-internet',
  templateUrl: '../base-receiver.html',
  styleUrls: ['../base-receiver.scss'],
})
export class SamInternetComponent extends BaseReceiver implements OnInit {
  constructor() {
    super(ExportType.Skiften);
  }

  ngOnInit() {}
}

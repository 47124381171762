<div class="progressbar progressbar-color"><span [style.width]="progressWidth"></span></div>
<div class="steps" fxLayout="row" fxLayoutAlign="space-around center">
  <div class="step" *ngFor="let step of wizardSteps" (click)="selectActiveStep(step.step)">
    <button color="primary" class="circle" [ngClass]="{'active': step.isDone}" mat-mini-fab>
      <mat-icon *ngIf="step.isDone && (!step.isCurrent || step.isLast)">check</mat-icon>
      <span *ngIf="!step.isDone || (step.isCurrent && !step.isLast)">{{step.step}}</span></button
    ><br />
    <span class="title">{{step.title}}</span>
  </div>
</div>
<mat-progress-spinner class="dialog-over-z" *ngIf="isLoading" [mode]="'indeterminate'"> </mat-progress-spinner>
<div *ngIf="isLoading" class="dv-wizard-shadow dialog-over-z"></div>
<ng-content></ng-content>

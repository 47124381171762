import { Injectable } from '@angular/core';

import { UserFileModel } from './models/FileModel';
import * as turf from '@turf/turf';

import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LegendModel, ColorSchemeModel } from './models/LegendModel';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { retry, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ClientService } from './client.service';

@Injectable()
export class LayerService {
  constructor(
    private http: HttpClient,
    private clientService: ClientService,
  ) {}

  public $downloadProgress: BehaviorSubject<number> = new BehaviorSubject(100);

  download(files: UserFileModel[]) {
    const clientId = this.clientService.getClient()
    this.$downloadProgress.next(0);
    let downloads = 0;

    const subs = files.map((file) =>
      this.http
        .get<GeoJSON.FeatureCollection<GeoJSON.GeometryObject>>(
          `${environment.dvApiUrl}klient/${clientId}/map/file/geodata/${file.fileId}`
        )
        .pipe(
          retry(1),
          map((featureCollection) => {
            file.fc = featureCollection;

            file.legend = this.generate(featureCollection);
            file.naring = 100;

            downloads++;

            this.$downloadProgress.next(
              Math.round((downloads / files.length) * 100)
            );

            return file;
          }),
          catchError((err) => {
            return throwError('Something went wrong..');
          })
        )
    );

    return forkJoin(subs);
  }

  generate(
    featureCollection: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>,
    opts?: any
  ): LegendModel {
    opts = opts || {};

    if (
      opts['valueField'] === undefined &&
      featureCollection.features.length > 0
    ) {
      const f = featureCollection.features[0];
      if ('giva' in f.properties) {
        opts.valueField = 'giva';
      } else if ('rate' in f.properties) {
        opts.valueField = 'rate';
      } else {
        opts.valueField = 'value';
      }
    }

    const _this = this,
      intervall = opts.intervall || 5,
      colorField = opts.colorField || 'color',
      valueField = opts.valueField,
      legend = new LegendModel();

    legend.title = opts.title || '';

    let valueList = [];

    let minValue = Number.MAX_SAFE_INTEGER;
    let minColor = '';
    let maxValue = Number.MIN_SAFE_INTEGER;
    let maxColor = '';

    const bR = 48;
    const bG = 93;
    const bB = 255;

    const rR = 255;
    const rG = 15;
    const rB = 23;
    let needToUpdateFC = false;

    featureCollection.features.forEach(function (feature) {
      if (!('value' in feature.properties)) {
        feature.properties['value'] = feature.properties[valueField];
      }

      if (feature.properties['value'] < minValue) {
        minValue = feature.properties['value'];
        minColor = feature.properties[colorField];
      }

      if (feature.properties['value'] > maxValue) {
        maxValue = feature.properties['value'];
        maxColor = feature.properties[colorField];
      }

      valueList.push({
        value: feature.properties['value'],
        color: feature.properties[colorField],
        areal: turf.area(<any>feature),
        va: (turf.area(<any>feature) * feature.properties['value']) / 10000,
      });
    });

    valueList = valueList.sort(function (a, b) {
      return a.value - b.value;
    });
    const steps = (maxValue - minValue) / intervall;

    let oldIndex = -1;
    let colorScheme: ColorSchemeModel = null;

    for (let i = 0; i < valueList.length; i++) {
      const index = Math.floor(valueList[i].value / steps);

      if (index !== oldIndex) {
        if (colorScheme || i === valueList.length - 1) {
          colorScheme.maxValue = valueList[i].value;
          colorScheme.maxColor = valueList[i].color;

          if (!colorScheme.minColor && !colorScheme.maxColor) {
            colorScheme.minColor =
              'rgb(' +
              [
                Math.floor(bB + ((rG - bG) * colorScheme.minValue) / maxValue),
                Math.floor(bG + ((rG - bG) * colorScheme.minValue) / maxValue),
                Math.floor(bR + ((bG - bR) * colorScheme.minValue) / maxValue),
              ].join(',') +
              ')';

            colorScheme.maxColor =
              'rgb(' +
              [
                Math.floor(bB + ((rG - bG) * colorScheme.maxValue) / maxValue),
                Math.floor(bG + ((rG - bG) * colorScheme.maxValue) / maxValue),
                Math.floor(bR + ((rR - bR) * colorScheme.maxValue) / maxValue),
              ].join(',') +
              ')';
            needToUpdateFC = true;
          }
        }
        colorScheme = new ColorSchemeModel(
          valueList[i].value,
          valueList[i].value,
          valueList[i].color,
          valueList[i].color
        );

        legend.colorScheme.push(colorScheme);
        oldIndex = index;
      }

      colorScheme.valueCount += 1;
      colorScheme.valueSum += valueList[i].value;
      colorScheme.areal += valueList[i].areal;
    }

    const tot = valueList.map((c) => c.va).reduce((c, p) => c + p);
    const totAreal =
      valueList.map((c) => c.areal).reduce((c, p) => c + p) / 10000;

    legend.medelText = 'Medelgiva: ' + (tot / totAreal).toFixed(0) + ' kg';
    legend.sumText = 'Total åtgång: ' + tot.toFixed(0) + ' kg';
    legend.title = 'GIVA';
    legend.sum = Math.round(tot);
    legend.valueField = 'value';
    legend.colorField = colorField;

    if (needToUpdateFC) {
      this.setColorField(featureCollection, legend);
    }

    return legend;
  }

  setColorField(
    featureCollection: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>,
    legend: LegendModel
  ) {
    featureCollection.features.forEach((f) => {
      const color = legend.colorScheme.find((c) => {
        return (
          f.properties['value'] >= c.minValue &&
          f.properties['value'] <= c.maxValue
        );
      }).maxColor;

      f.properties['color'] = color;
    });
  }

  setNewTotal(
    file: UserFileModel,
    newTotal: number,
    newNaring: number
  ): UserFileModel {
    file.fc.features.forEach((f) => {
      if (!f.properties['orgValue']) {
        f.properties['orgValue'] = f.properties['value'];
      }

      f.properties['value'] =
        (f.properties['orgValue'] * (newTotal / file.legend.sum)) /
        (newNaring / 100);
    });

    file.naring = newNaring;
    file.legend = this.generate(file.fc);

    return file;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientDialogResult, DvAuthService, DvUserService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-unauthorized-dialog',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<UnauthorizedComponent>,
    private dvAuthService: DvAuthService,
    private dvUserService: DvUserService
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close(false);
  }

  logOut() {
    this.dvAuthService.signOut();
  }

  changeClient() {
    const clientDialogRef = this.dvUserService.openChangeClientDialog();

    clientDialogRef.afterClosed().subscribe((result: ClientDialogResult) => {
      if (result?.clientId) {
        this.dialogRef.close(false);
      }
    });
  }
}

<mat-card class="container">
  <mat-card-content>
    <div fxLayout="row">
      <div class="left" fxFlex fxLayout="column" fxLayoutAlign="space-around">
        <h3>{{ "_export_welcome" | dvt }}</h3>
        <p>{{ "_export_ingress" | dvt }}</p>
        <h4>{{ "_export_no_account" | dvt }}</h4>
        <p>{{ "_export_account_explained" | dvt }}</p>
        <div class="button-wrapper" fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-md="column">
          <button
            mat-raised-button
            type="submit"
            color="primary"
            [disabled]="loading === true"
            style="margin-bottom: 15px"
            (click)="createAccount()"
          >
            {{ "_export_create_account" | dvt }}
          </button>
          <button
            mat-raised-button
            type="submit"
            color="accent"
            [disabled]="loading === true"
            style="margin-bottom: 15px"
            (click)="login()"
          >
            {{ "_export_login" | dvt }}
          </button>
        </div>
      </div>
      <div class="right">
        <img src="assets/image-export.png" alt="" />
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div fxLayout="column" fxLayoutAlign="start center">
  <h2>{{ "_common_parcels" | dvt }}</h2>
  <p>{{ "_export_select_fields" | dvt }}</p>
</div>
<div fxLayout="column" fxLayoutAlign="start center">
  <mat-progress-bar *ngIf="!loaded" mode="query"></mat-progress-bar>
  <div class="container mat-elevation-z8">
    <mat-table #table [dataSource]="skiftenDatasource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="brukEnhNamn">
        <mat-header-cell *matHeaderCellDef>{{ "_common_agricultural_unit" | dvt }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.brukEnhNamn }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="namn">
        <mat-header-cell *matHeaderCellDef>{{ "_common_name" | dvt }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.namn }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="eu">
        <mat-header-cell *matHeaderCellDef>{{ "_export_eu_bet" | dvt }}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.euNr }}{{ element.euBok }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="huvudgrodaBenamning">
        <mat-header-cell *matHeaderCellDef>{{ "_common_main_crop" | dvt }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.huvudgrodaBenamning }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="areal">
        <mat-header-cell *matHeaderCellDef>{{ "_common_area" | dvt }}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.areal }} ha</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></mat-row>
    </mat-table>
  </div>
</div>

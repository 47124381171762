import { Component, OnInit } from '@angular/core';
import * as ol from 'openlayers';

@Component({
  selector: 'dv-map',
  template: '<div id="map" style="flex:auto;"></div><ng-content></ng-content>',
  styles: [':host{flex:auto; display:flex; } '],
})
export class DvMapComponent implements OnInit {
  private map: ol.Map = null;
  private _layers: { [key: string]: ol.layer.Vector } = {};

  constructor() {}

  clearMapSource() {
    for (const key in this._layers) {
      this.removeMapSource(key);
    }
  }

  setMapSource(
    key: string,
    fc: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>
  ) {
    if (!this._layers[key]) {
      this._layers[key] = new ol.layer.Vector({
        source: new ol.source.Vector({
          features: new ol.format.GeoJSON({
            featureProjection: this.map.getView().getProjection(),
            defaultDataProjection: 'EPSG:4326',
          }).readFeatures(fc),
        }),
        style: function (feature, resolution) {
          return new ol.style.Style({
            fill: new ol.style.Fill({
              color: feature.getProperties()['color'],
            }),
          });
        },
      });

      this.map.addLayer(this._layers[key]);
      this.map.getView().fit(this._layers[key].getSource().getExtent());
    }
  }

  removeMapSource(key: string) {
    if (this._layers[key]) {
      this.map.removeLayer(this._layers[key]);
      delete this._layers[key];
    }
  }

  reRender() {
    this.map.updateSize();
  }

  ngOnInit() {
    this.map = new ol.Map({
      layers: [
        new ol.layer.Tile({
          source: new ol.source.XYZ({
            url:
              'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoidmlrdG9yYWhsYmVyZyIsImEiOiJjajRwNzZpc3QyNGQ5MzNwZDExamVhMmt1In0.Jy1xnyhMHP2HugnB1hBK5g',
          }),
        }),
      ],

      target: 'map',
      view: new ol.View({
        center: [0, 0],
      }),
    });
  }
}

<dv-wizard #wizard [activeStep]="currStepp">
  <dv-wizardstep [title]="'_common_machine' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <p>{{ "_export_logmaster_select_machine" | dvt }}</p>
      <dv-logmaster-machine (selected)="setLoggers($event)" style="width: 550px"></dv-logmaster-machine>
    </div>
    <mat-card>
      <button style="float: right" [disabled]="loggers.length === 0" mat-raised-button (click)="wizard.activeStep = 2"
        color="primary">
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>
      </button>
    </mat-card>
  </dv-wizardstep>

  <dv-wizardstep [title]="'_common_type_of_data' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <h2>{{ "_export_logmaster_select_type_of_data" | dvt }}</h2>
      <p>{{ "_export_logmaster_select_form_to_export" | dvt }}</p>
      <div style="margin-top: 55px">
        <strong>{{ "_export_logmaster_type_of_export" | dvt }}</strong>
        <div class="work-mode" (click)="modeWork = false; modeRaw = true">
          <mat-checkbox class="example-margin" [checked]="modeRaw" (click)="modeWork = false; modeRaw = true">
          </mat-checkbox>
          <img src="/assets/icon-punktdata.svg" alt="" />
          <div>
            <h3>{{ "_export_logmaster_points_of_data" | dvt }}</h3>
            {{ "_export_logmaster_explain_download_of_data" | dvt }}
          </div>
        </div>
        <div class="work-mode" (click)="modeWork = true; modeRaw = false">
          <mat-checkbox class="example-margin" [checked]="modeWork" (click)="modeWork = true; modeRaw = false">
          </mat-checkbox>
          <img src="/assets/icon-skiftesdata.svg" alt="" />
          <div>
            <h3>{{ "_export_logmaster_type_of_work" | dvt }}</h3>
            {{ "_export_logmaster_explain_type_of_work" | dvt }}
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-top: 55px">
        <strong>{{ "_common_date_selection" | dvt }}</strong>
        <div>
          <mat-form-field appearance="outline">
            <input autocomplete="off" matInput [matDatepicker]="pickerFrom" [(ngModel)]="from"
              placeholder="{{ '_common_from' | dvt }}" />
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input autocomplete="off" matInput [matDatepicker]="pickerTo" [(ngModel)]="to"
              placeholder="{{ '_common_to' | dvt }}" />
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="modeWork" style="display: flex; flex-direction: column; margin-top: 55px">
        <strong>{{ "_export_logmaster_type_of_work_to_export" | dvt }}</strong>
        <div style="display: flex; flex-direction: row; margin-top: 15px; justify-content: space-evenly">
          <mat-checkbox [(ngModel)]="includeField">{{ "_common_fields" | dvt }}</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeSkifte">{{ "_common_parcels" | dvt }}</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeZone">{{ "_common_zones" | dvt }}</mat-checkbox>
          <mat-checkbox [(ngModel)]="includeWKT">{{ "_common_wkt" | dvt }}</mat-checkbox>
        </div>
        <p style="font-style: italic">
          {{ "_export_logmaster_select_work_and_wkt" | dvt }}
        </p>
      </div>
    </div>
    <mat-card>
      <button style="float: right" [disabled]="from > to || !from || !to" mat-raised-button
        (click)="wizard.activeStep = 3; export()" color="primary">
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_export' | dvt">
    <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; flex: auto">
      <div *ngIf="!exportDone">
        <h2 style="text-align: center">{{ "_export_logmaster_processing_data" | dvt }}</h2>
        <mat-progress-bar mode="determinate" [value]="doneLinks() / links.length"></mat-progress-bar>
      </div>
      <h2 div *ngIf="exportDone" style="text-align: center">{{ "_export_logmaster_processing_complete" | dvt }}</h2>

      <div *ngFor="let link of links" style="margin-top: 50px">
        <span *ngIf="link.done">
          <a matLine *ngIf="!isSkira" mat-raised-button href="{{ link.url }}" target="_blank">
            <mat-icon>save_alt</mat-icon> {{ link.name }}
          </a>
          <span *ngIf="isSkira">
            <mat-icon color="primary">done</mat-icon> {{ link.name }} skickat till Skira
          </span>
        </span>
        <span style="display: flex; flex-direction: row" *ngIf="!link.done">
          <mat-progress-spinner [diameter]="25" [mode]="'indeterminate'" style="margin-right: 15px">
          </mat-progress-spinner>
          {{ link.name }}
        </span>
      </div>
    </div>
    <mat-card>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>
        {{ "_common_back_to_start" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
</dv-wizard>
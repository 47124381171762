import { KeyValue } from '@angular/common';
import { LegendModel } from './LegendModel';

export class UserFileModel {
  jobKey: string;
  klientId: number;
  fileId: string;
  name: string;
  created: string;
  imported: string;
  userId: number;
  storageType: StorageType;
  fc?: GeoJSON.FeatureCollection<GeoJSON.GeometryObject> = null;
  legend?: LegendModel;
  naring?: number;
}

export interface GeoFile {
  fileId: number;
  name: string;
  fileType: string;
  ownerClient: number;
  rawFileId: number;
  originReference: string;
  storagePath: string;
  importedDate: string;
  importer: string;
  importerVersion: string;
  worker: string;
  workerVersion: string;
  status: string;
  metadata: KeyValue<string, string>[];
}

export enum FileType {
  NSensor,
  Markkartering,
  Unknown,
  MaskinLogger,
  Skordedata,
  Tilldelning,
  LogMaster,
}
export enum StorageType {
  TableStore,
  Sql,
  FileStore,
}
export enum StorageFormat {
  Bin,
  Json,
  Row,
}

<mat-card>
  <mat-card-title>{{ "_common_agricultural_units" | dvt }}</mat-card-title>
  <mat-checkbox style="margin-left: 16px" (change)="toggleSelect($event.checked)">{{
    "_common_select_all" | dvt
  }}</mat-checkbox>
  <mat-selection-list #selectedBruk>
    <mat-list-option *ngFor="let b of bruk" [value]="b" checkboxPosition="before">
      {{ b.namn }}
    </mat-list-option>
  </mat-selection-list>
</mat-card>

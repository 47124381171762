import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LoggerModel } from '../../models/Models';
import { ClientService } from '../../client.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'dv-logmaster-machine',
  templateUrl: './logmaster-machine.component.html',
  styleUrls: ['./logmaster-machine.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LogmasterMachineComponent implements OnInit {
  filesDatasource = new MatTableDataSource<LoggerModel>();
  _selectedFiles: LoggerModel[] = [];
  selection = new SelectionModel<LoggerModel>(true, []);
  loaded = false;

  @Output() selected = new EventEmitter<LoggerModel[]>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private clientService: ClientService,
    private cd: ChangeDetectorRef
  ) {
    this.selection.changed.subscribe((ev) => {
      this.selected.next(this.selection.selected);
      this.cd.detectChanges();
    });
  }

  ngOnInit() {
    this.clientService.getLoggers().subscribe((res) => {
      this.filesDatasource.data = res;
      this.cd.detectChanges();
    });
    this.filesDatasource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filesDatasource.data.length;

    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.filesDatasource.data.forEach((row) => this.selection.select(row));
    this.cd.detectChanges();
  }
}

import { AfterContentInit } from '@angular/core';
import {
  Component,
  Input,
  Output,
  ContentChildren,
  QueryList,
  EventEmitter,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { WizardStepComponent } from './WizardStepComponent';

@Component({
  moduleId: module.id,
  selector: 'dv-wizard',
  templateUrl: 'WizardComponent.html',
  styleUrls: ['WizardComponent.css'],
  host: { class: 'dv-background' },
})
export class WizardComponent implements AfterContentInit {
  progressWidth: string;
  private _currentStep: number;
  private _step: number;

  @ContentChildren(WizardStepComponent) wizardSteps: QueryList<
    WizardStepComponent
  >;

  @Input() isLoading: boolean;

  @Output() activeStepChanged: EventEmitter<number>;

  constructor(private cd: ChangeDetectorRef, private zone: NgZone) {
    this.activeStepChanged = new EventEmitter<number>();
    this._currentStep = 0;
    this._step = 1;
  }

  set progress(value: number) {
    this.progressWidth = value + '%';
  }

  @Input() set activeStep(value: number) {
    this._step = value;

    if (!this.wizardSteps) {
      return; // Not initiated yet
    }

    if (this._currentStep === value) {
      return;
    }

    if (this._currentStep + 1 < value) {
      return; // Not allowed to skip any step
    }

    const steps = this.wizardSteps.toArray();

    for (const wizardStep of steps) {
      wizardStep.isDone = false;
      wizardStep.current = false;

      if (wizardStep.step <= value) {
        wizardStep.isDone = true;

        if (wizardStep.step === value) {
          wizardStep.current = true;
          this._currentStep = wizardStep.step;
        }
      }
    }

    this.progress = Math.round((value / steps.length) * 100);
    this.activeStepChanged.emit(this._currentStep);
  }

  selectActiveStep(step: number) {
    if (this._currentStep < step) {
      return; // Only allowed to go backwards
    }
    this.activeStep = step;
  }

  ngAfterContentInit() {
    let step = 1;

    for (const wizardStep of this.wizardSteps.toArray()) {
      if (this.wizardSteps.toArray().length === step) {
        wizardStep.isLast = true;
      }

      wizardStep.step = step++;
    }

    this.activeStep = this._step || 1;
    this.cd.detectChanges();
  }
}

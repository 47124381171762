<dv-wizard #wizard [activeStep]="currStepp">
  <dv-wizardstep [title]="'_common_allocation_file' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto">
      <h2 style="text-align: center; margin-bottom: 0">{{ "_common_select_allocation_file" | dvt }}</h2>
      <p style="text-align: center">{{ "_common_select_one_or_multiple_allocation_files" | dvt }}</p>
      <dv-select-file (selectedFiles)="selectedFiles = $event"></dv-select-file>

      <p style="text-align: center">{{ "_common_create_new_allocation_file" | dvt }}</p>
      <div class="app-links" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="center">
        <a mat-button href="https://cropsat.se" target="_blank" rel="noopener noreferrer">
          <img src="/assets/cropsat-icon@2x.png" alt="" />CropSAT</a
        >
        <a mat-button href="http://markdata.se" target="_blank" rel="noopener noreferrer">
          <img src="/assets/markdata-icon@2x.png" alt="" />Markdata</a
        >
        <a mat-button href="https://datavaxt.com/sv/produkter/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/dv-icon@2x.png" alt="" />DataVäxt</a
        >
      </div>
      <div style="height: 60px"></div>
    </div>
    <mat-card>
      <button
        style="float: right"
        mat-raised-button
        (click)="setStepPreview()"
        color="primary"
        [disabled]="selectedFiles.length === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>{{ "_common_back_to_start" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_preview' | dvt">
    <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; flex: auto">
      <h2 style="text-align: center">{{ "_common_verify_allocation_file" | dvt }}</h2>
      <p style="text-align: center">
        {{ "_common_verify_allocation_file_before_export" | dvt }}
      </p>
      <mat-select
        placeholder="{{ '_common_select_allocation_file_to_verify' | dvt }}"
        [(ngModel)]="currentFile"
        (selectionChange)="viewFile($event.value)"
        style="width: 70%; margin-bottom: 10px"
        *ngIf="selectedFiles.length > 1"
      >
        <mat-option *ngFor="let file of selectedFiles" [value]="file">
          {{ file.name }}
        </mat-option>
      </mat-select>

      <dv-map #map style="width: 100%">
        <dv-map-legend #legend [fxShow]="showLegend"></dv-map-legend>
      </dv-map>
    </div>
    <mat-card>
      <dv-giva #giva [file]="currentFile" (givaChanged)="viewFile(currentFile)"></dv-giva>
      <button mat-raised-button (click)="giva.show = true">{{ "_common_adjust_allocation_file" | dvt }}</button>
      <mat-slide-toggle [(ngModel)]="showLegend">Legend</mat-slide-toggle>
      <button
        class="next-btn"
        style="float: right"
        mat-raised-button
        (click)="wizard.activeStep = 3"
        color="primary"
        [disabled]="selectedFiles.length === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="wizard.activeStep = 1">
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'Exportera'">
    <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; flex: auto">
      <h2 style="text-align: center">{{ "_common_file_soon_done" | dvt }}</h2>
      <p style="text-align: center">{{ "_common_click_to_download" | dvt }}</p>
      <button color="accent" mat-raised-button (click)="export('isoxml')">
        {{ "_common_download" | dvt }}
      </button>
    </div>
    <mat-card>
      <button style="float: right" mat-raised-button (click)="wizard.activeStep = 1">
        {{ "_common_select_new_files" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
</dv-wizard>

import {
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, retryWhen, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { FileType, GeoFile } from './models/FileModel';
import { FileUploadResultModel } from './models/FileUploadResultModel';
import {
    AnvandareModel,
    BrukningsenhetModel,
    FutureFarmExportModel,
    GrunddataModel,
    KlientModel,
    LoggerModel,
    SAMExportInput,
    SCBClientInput,
    SkifteDetielsModel,
    TabellGodselModel,
    TabellUtsadeModel,
    TabellVaxtskyddModel,
    VodkaExportModel,
} from './models/Models';
import { OAuthService } from './oAuth/oauth.service';
import { UrlService } from './services/url.service';
import { Icons } from './models/app/Provider';
import { throwError } from 'rxjs/internal/observable/throwError';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ClientExceptionResponse, Exception } from '@dv/insights';
import { DvClientService, DvToolbarTranslateService } from '@dv/toolbar-msal';

@Injectable()
export class ClientService {
    private clientId: number = null;

    constructor(
        private http: HttpClient,
        private oauth: OAuthService,
        private translateService: DvToolbarTranslateService,
        private urlService: UrlService,
        private dvClientService: DvClientService
    ) {
        this.dvClientService.client$
            .pipe(take(1))
            .subscribe(client => this.clientId = client?.id);
    }

    getUser() {
        return this.http.get<AnvandareModel>(
            environment.dvApiUrl + 'user'
        );
    }

    setClient(id: number) {
        this.clientId = id;
    }

    getClient() {
        return this.clientId;
    }

    getFiles(clientId: number, filetype: FileType) {
        const url = `${environment.dvApiUrl}klient/${clientId}/map/file/geodata`;

        return this.http
            .get<GeoFile[]>(url)
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((files) => files
                    .filter(file => file.fileType === 'Tilldelning')
                    .sort((a, b) => (
                        new Date(b.importedDate).getTime() -
                        new Date(a.importedDate).getTime()
                    ))
                )
            );
    }

    getClients() {
        return this.http.get<KlientModel[]>(
            environment.dvApiUrl + 'klient'
        );
    }

    upload(
        featureCollections: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>[],
        target: string
    ): Observable<FileUploadResultModel> {
        switch (target) {
            case 'trimble-display':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/trimble/tilldelning/file',
                        featureCollections,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/trimble-btn@2x.png',
                                res.headers.get('location')
                            );
                        })
                    );
            case 'trimble':
                return of(
                    new FileUploadResultModel(
                        true,
                        this.translateService.t(
                            '_export_description_file_done'
                        ),
                        '/assets/trimble@2x.png',
                        'test.html'
                    )
                );

            case 'deere-display':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/deere/tilldelning/file',
                        featureCollections,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/jd-btn@2x.png',
                                res.headers.get('location')
                            );
                        })
                    );

            case 'deere':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/deere/tilldelning',
                        featureCollections,
                        {
                            observe: 'response',
                            responseType: 'text',
                        }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                true,
                                this.translateService.t(
                                    '_export_description_file_uploaded_deere'
                                ),
                                '/assets/jd@2x.png'
                            );
                        })
                    );

            case 'isoxml':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/isoxml/tilldelning',
                        featureCollections,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/kvernelenad@2x.png',
                                res.headers.get('location')
                            );
                        })
                    );
            case 'shp':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/map/shp',
                        featureCollections,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/Skiften@2x.png',
                                res.headers.get('location')
                            );
                        })
                    );
            case 'yara':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/yara/tilldelning',
                        featureCollections,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/yara.png',
                                res.headers.get('location')
                            );
                        })
                    );
        }
    }

    uploadSkifte(
        year: number,
        brukEnhet: number[],
        target: string
    ): Observable<FileUploadResultModel> {
        switch (target) {
            case 'trimble':
                return of(
                    new FileUploadResultModel(
                        true,
                        this.translateService.t(
                            '_export_description_file_created_download'
                        ),
                        '/assets/trimble@2x.png',
                        'test.html'
                    )
                );

            case 'trimble-display':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/trimble/skifte/' +
                            year,
                        brukEnhet,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/trimble@2x.png',
                                res.headers.get('location')
                            );
                        })
                    );

            case 'deere':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/deere/skifte/' +
                            year,
                        brukEnhet,
                        { observe: 'response' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                true,
                                this.translateService.t(
                                    '_export_parcels_migrated_deere'
                                ),
                                '/assets/jd@2x.png'
                            );
                        })
                    );

            case 'isoxml':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/isoxml/skifte',
                        brukEnhet,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t(
                                    '_export_description_file_done'
                                ),
                                '/assets/kvernelenad@2x.png',
                                res.headers.get('location')
                            );
                        })
                    );
        }
    }

    exportSam(input: SAMExportInput, ar: number) {
        return this.http
            .post(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/' +
                    ar +
                    '/eu/sam/export',
                input,
                { observe: 'response', responseType: 'text' }
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((res) => {
                    return new FileUploadResultModel(
                        res.ok,
                        this.translateService.t(
                            '_export_description_file_done'
                        ),
                        '/assets/sam.gif',
                        res.headers.get('location')
                    );
                })
            );
    }

    exportScb(input: SCBClientInput, ar: number, type: string) {
        switch (type) {
            case 'godsel':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/scb/' +
                            ar +
                            '/godsel',
                        input,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t('_export_done_scb'),
                                '/assets/scb.png',
                                res.headers.get('location')
                            );
                        })
                    );
            case 'vaxtskydd':
                return this.http
                    .post(
                        environment.dvApiUrl +
                            'klient/' +
                            this.clientId +
                            '/external/scb/' +
                            ar +
                            '/vaxtskydd',
                        input,
                        { observe: 'response', responseType: 'text' }
                    )
                    .pipe(
                        retryWhen((err) =>
                            err.pipe(
                                mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                            )
                        ),
                        map((res) => {
                            return new FileUploadResultModel(
                                res.ok,
                                this.translateService.t('_export_done_scb'),
                                '/assets/scb.png',
                                res.headers.get('location')
                            );
                        })
                    );
        }
    }

    exportVodka(year: number, model: VodkaExportModel) {
        return this.http
            .post(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/external/vodka/' +
                    year +
                    '/export',
                model,
                { observe: 'response', responseType: 'text' }
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((res) => {
                    return res.headers.get('location');
                })
            );
    }

    exportCropplanAsMail(input: FutureFarmExportModel, ar: number) {
        return this.http
            .post(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/external/futurefarm/' +
                    ar +
                    '/cropplan',
                input,
                { observe: 'response', responseType: 'text' }
            )
            .pipe(
                map((res) => {
                    return new FileUploadResultModel(
                        res.ok,
                        this.translateService.t('_export_done_futurefarm'),
                        '/assets/FutureFarm.png',
                        res.headers.get('location')
                    );
                })
            );
    }

    exportFuturefarmCropplan(input: FutureFarmExportModel, ar: number) {
        return this.http
            .post(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/external/futurefarm/' +
                    ar +
                    '/cropplan',
                input,
                { observe: 'response', responseType: 'text' }
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((res) => {
                    return new FileUploadResultModel(
                        res.ok,
                        this.translateService.t('_export_done_futurefarm'),
                        '/assets/FutureFarm.png',
                        res.headers.get('location')
                    );
                })
            );
    }

    exportCropplan(input: FutureFarmExportModel, ar: number) {
        return this.http
            .post(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/external/futurefarm/' +
                    ar +
                    '/cropplan',
                input,
                { observe: 'response', responseType: 'text' }
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((res) => {
                    return new FileUploadResultModel(
                        res.ok,
                        this.translateService.t('_export_done_cropplan'),
                        '/assets/crop.svg',
                        res.headers.get('location')
                    );
                })
            );
    }

    exportSkiraCropplan(input: FutureFarmExportModel, ar: number) {
        return this.http
            .post(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/external/skira/' +
                    ar +
                    '/cropplan',
                input,
                { observe: 'response', responseType: 'text' }
            )
            .pipe(
                map((res) => {
                    return new FileUploadResultModel(
                        res.ok,
                        this.translateService.t('_export_done_skira'),
                        '/assets/SkiraBeta_Logo_grön-vit.svg'
                    );
                })
            );
    }

    getLastArForKlient() {
        return this.http
            .get<number[]>(
                environment.dvApiUrl + 'klient/' + this.clientId + '/ar'
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((arForClient) => {
                    // we need to find a year to use,
                    // leats start with the current year
                    const now = new Date().getFullYear();

                    let ar = arForClient.find((a) => a === now);
                    // if current year do not exits try the latest
                    if (ar === undefined) {
                        ar = arForClient.sort((a, b) => b - a)[0];
                    }

                    return ar;
                })
            );
    }

    // Return all years with data for current client
    getArForKlient(): Observable<number[]> {
        return this.http
            .get<number[]>(
                environment.dvApiUrl + 'klient/' + this.clientId + '/ar'
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((arForClient) => {
                    return arForClient;
                })
            );
    }

    getSkiften(year: number) {
        return this.http
            .get<SkifteDetielsModel[]>(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/' +
                    year +
                    '/skifte?expand=true'
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((skiften) => {
                    return skiften;
                })
            );
    }

    getBruk(klientId: number) {
        return this.http.get<BrukningsenhetModel[]>(
            environment.dvApiUrl +
                'klient/' +
                klientId +
                '/' +
                new Date().getFullYear() +
                '/brukningsenhet'
        );
    }

    getGrunddata() {
        return this.http.get<GrunddataModel>(
            environment.dvApiUrl +
                'klient/' +
                this.clientId +
                '/grunddata'
        );
    }

    getTabellUtsade(year: number) {
        return this.http
            .get<TabellUtsadeModel[]>(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/' +
                    year +
                    '/tabellutsade'
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((utsade) => {
                    return utsade;
                })
            );
    }

    getTabellGodsel(year: number) {
        return this.http
            .get<TabellGodselModel[]>(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/' +
                    year +
                    '/tabellgodsel'
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((godsel) => {
                    return godsel;
                })
            );
    }

    getTabellVaxtskydd(year: number) {
        return this.http
            .get<TabellVaxtskyddModel[]>(
                environment.dvApiUrl +
                    'klient/' +
                    this.clientId +
                    '/' +
                    year +
                    '/tabellvaxtskydd'
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((vaxtskydd) => {
                    return vaxtskydd;
                })
            );
    }

    getLoggers() {
        return this.http.get<LoggerModel[]>(
            environment.dvApiUrl +
                'klient/' +
                this.clientId +
                '/logger/maskinlogger/connections'
        );
    }
    exportLogmasterToSkira(
        mode: 'work' | 'raw',
        maskinId: number,
        from: string,
        to: string,
        includeField: boolean,
        includeSkifte: boolean,
        includeZone: boolean,
        includeWKT: boolean
    ) {
        if (mode === 'work') {
            let include = '&work=' + includeField;
            include += '&fieldWork=' + includeSkifte;
            include += '&zoneWork=' + includeZone;
            include += '&includeWKT=' + includeWKT;

            return this.http
                .get(
                    environment.dvApiUrl +
                        'klient/' +
                        this.clientId +
                        '/external/skira/' +
                        maskinId +
                        '/work?from=' +
                        from +
                        '&to=' +
                        to +
                        include,
                    { observe: 'response' }
                )
                .pipe(
                    retryWhen((err) =>
                        err.pipe(
                            mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                        )
                    )
                );
        } else {
            return this.http
                .get(
                    environment.dvApiUrl +
                        'klient/' +
                        this.clientId +
                        '/external/skira/' +
                        maskinId +
                        '?from=' +
                        from +
                        '&to=' +
                        to,
                    { observe: 'response' }
                )
                .pipe(
                    retryWhen((err) =>
                        err.pipe(
                            mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                        )
                    )
                );
        }
    }
    exportLogmaster(
        mode: 'work' | 'raw',
        maskinId: number,
        from: string,
        to: string,
        includeField: boolean,
        includeSkifte: boolean,
        includeZone: boolean,
        includeWKT: boolean
    ) {
        if (mode === 'work') {
            let include = '&work=' + includeField;
            include += '&fieldWork=' + includeSkifte;
            include += '&zoneWork=' + includeZone;
            include += '&includeWKT=' + includeWKT;

            return this.http
                .get(
                    environment.dvApiUrl +
                        'klient/' +
                        this.clientId +
                        '/external/logmaster/' +
                        maskinId +
                        '/work?from=' +
                        from +
                        '&to=' +
                        to +
                        include,
                    { observe: 'response' }
                )
                .pipe(
                    retryWhen((err) =>
                        err.pipe(
                            mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                        )
                    ),
                    map((res) => {
                        return res.headers.get('Location');
                    })
                );
        } else {
            return this.http
                .get(
                    environment.dvApiUrl +
                        'klient/' +
                        this.clientId +
                        '/external/logmaster/' +
                        maskinId +
                        '?from=' +
                        from +
                        '&to=' +
                        to,
                    { observe: 'response' }
                )
                .pipe(
                    retryWhen((err) =>
                        err.pipe(
                            mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                        )
                    ),
                    map((res) => {
                        return res.headers.get('Location');
                    })
                );
        }
    }

    isUserAuthenticated(providerShortName: string, userId: number) {
        return this.http
            .get(
                this.urlService.getProviderAuthenticateUrl(
                    providerShortName,
                    this.clientId,
                    userId
                ),
                {
                    observe: 'response',
                }
            )
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => this.handleErrorResponse(res))
                    )
                ),
                map((res) => {
                    return res.headers.get('Location');
                })
            );
    }

    downloadData(
        providerShortName: string,
        type: string,
        action: any,
        params: any
    ): Observable<FileUploadResultModel> {
        const url = this.urlService.getProviderUrl(
            providerShortName,
            type,
            action,
            this.clientId
        );

        return this.http
            .post(url, params, { observe: 'response', responseType: 'text' })
            .pipe(
                map((res) => {
                    return new FileUploadResultModel(
                        res.ok,
                        this.translateService.t(
                            '_export_description_file_done'
                        ),
                        Icons[providerShortName],
                        res.headers.get('location')
                    );
                }),
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) => {
                            // this.dvInsightsService.logException(res);

                            if (res.status === 401) {
                                return this.oauth.login(
                                    res.headers.get('location'),
                                    Icons[providerShortName]
                                );
                            }

                            return this.generateErrorResponse(res);
                        })
                    )
                )
            );
    }

    uploadData(
        providerShortName: string,
        type: string,
        action: any,
        params: any
    ): Observable<FileUploadResultModel> {
        const url = this.urlService.getProviderUrl(
            providerShortName,
            type,
            action,
            this.clientId
        );

        return this.http
            .post(url, params, {
                observe: 'response',
                responseType: 'text',
            })
            .pipe(
                retryWhen((err) =>
                    err.pipe(
                        mergeMap((res: HttpErrorResponse) =>
                            this.handleErrorResponse(res)
                        ),
                    ),
                ),
                map((res) => {
                    let result = this.translateService.t('Successfully exported {0} fields');
                    result = result.replace('{0}', res.body);

                    return new FileUploadResultModel(
                        true,
                        result,
                        '/assets/jd@2x.png'
                    );
                })
            );
    }

    generateErrorResponse(response: HttpErrorResponse) {
        let exceptionResponse = response.error as ClientExceptionResponse;

        if (!isSerializedException(response.error)) {
            exceptionResponse = JSON.parse(
                response.error.message
            ) as ClientExceptionResponse;
        }

        const clientError: Exception = new Exception(
            response.status,
            response.error.message,
            SeverityLevel.Error,
            exceptionResponse
        );

        return throwError(clientError);
    }

    private handleErrorResponse(res: HttpErrorResponse): Observable<any> {
        if (res.status === 401) {
            return this.oauth.login(
                res.headers.get('location'),
                '/assets/jd@2x.png'
            );
        }
    }
}

type exceptions = ClientExceptionResponse;

function isSerializedException(error: exceptions) {
    if (error.errorCode !== undefined) {
        return true;
    }
}

export enum Download {
    field = 'field',
    prescription = 'prescription',
}

export enum Upload {
    field = 'field',
    prescription = 'prescription',
}

import { Component, ChangeDetectorRef } from '@angular/core';
import { BrukningsenhetModel } from '../models/Models';
import { ClientService, Download } from '../client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OperationDoneDialogComponent } from '../common-components/operation-done-dialog/operation-done-dialog.component';

@Component({
    selector: 'dv-field-export',
    templateUrl: './field-export.component.html',
    styleUrls: ['./field-export.component.scss'],
})
export class FieldExportComponent {
    currentStepInWizard: number = undefined;
    selectedBrukningsenheter: BrukningsenhetModel[] = [];
    year: number;

    constructor(
        private clientService: ClientService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.clientService.setClient(params['client']);
        });
    }

    selectedBruk(ev) {
        this.selectedBrukningsenheter = ev;
        this.cd.detectChanges();
    }

    selectedYear(ev) {
        this.year = ev;
    }

    export(providerShortName: string) {
        const params = {
            year: this.year.toString(),
            farmIds: this.selectedBrukningsenheter.map((b) => b.id),
        };

        this.dialog.open(OperationDoneDialogComponent, {
            data: this.clientService.downloadData(
                providerShortName,
                'download',
                Download.field,
                params
            ),
            disableClose: true,
        });
    }

    goToStart() {
        this.activatedRoute.params.subscribe((params) => {
            this.router.navigate(['client', params['clientid']]);
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { BrukningsenhetModel, SCBClientInput } from '../../../models/Models';
import { ClientService } from '../../../client.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';
import { Receiver } from '../../receiver';

@Component({
  selector: 'dv-scb-fertilizer',
  templateUrl: './scb-fertilizer.component.html',
  styleUrls: ['./scb-fertilizer.component.scss'],
})
export class ScbFertilizerComponent extends Receiver implements OnInit {
  currStepp: number = undefined;
  selectedBrukningsenheter: BrukningsenhetModel[] = [];
  selectedSkifteId: number[] = [];
  year = 0;
  org: string;

  constructor(
    private clientService: ClientService,
    private dialog: MatDialog,
    activatedRoute: ActivatedRoute,
    router: Router
  ) {
    super(activatedRoute, router);

    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }

  ngOnInit() {}
  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
  }

  selectedYear(ev) {
    this.year = ev;
  }

  export() {
    const input: SCBClientInput = <SCBClientInput>{};
    input.skifteId = this.selectedSkifteId;
    input.orgnr = this.org;

    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.exportScb(input, this.year, 'godsel'),
      disableClose: true,
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DvAuthService } from '@dv/toolbar-msal';
import { take, tap } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public router: Router, private dvAuthService: DvAuthService) {}

  canActivate() {
    return this.dvAuthService.isLoggedIn$
      .pipe(
        take(1),
        tap(isLoggedIn => {
          if (!isLoggedIn) {
            this.router.navigateByUrl('login');
          }
        })
      )
  }
}

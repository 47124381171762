<div class="mat-elevation-z8" style="width: 100%; max-width: 750px; min-width: 310px; margin: auto">
  <mat-table #table [dataSource]="filesDatasource" matSort>
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" style="width: 75px">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container cdkColumnDef="maskinNamn">
      <mat-header-cell *cdkHeaderCellDef style="text-align: left" mat-sort-header> Namn </mat-header-cell>
      <mat-cell *cdkCellDef="let row"> {{ row.maskinNamn }} </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="['select', 'maskinNamn']"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: ['select', 'maskinNamn']" (click)="selection.toggle(row)"> </mat-row>
  </mat-table>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientService } from '../client.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UnauthorizedComponent } from '../common-components/unauthorized/unauthorized.component';
import { take, takeUntil } from 'rxjs/operators';
import { DvClientService, DvAuthService } from '@dv/toolbar-msal';
import { Subject } from 'rxjs';

@Component({
  selector: 'dv-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loading = false;
  private unsub = new Subject<void>();

  constructor(
    private clientService: ClientService,
    private router: Router,
    private dialog: MatDialog,
    private dvClientService: DvClientService,
    private dvAuthService: DvAuthService
  ) {}

  ngOnInit() {
    this.loading = false;
    this.dvClientService.client$
      .pipe(take(1))
      .subscribe(client => {
        if (!client) {
          return;
        }

        if (client.rattigheter.exportData) {
          this.clientService.setClient(client.id);
          this.router.navigate(['client', client.id]);
        } else {
          this.dialog
            .open(UnauthorizedComponent)
            .afterClosed()
            .subscribe(() => {});
        }
      });

    this.dvAuthService.abortedSignIn$
      .pipe(takeUntil(this.unsub))
      .subscribe(() => {
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  login() {
    this.loading = true;
    this.dvAuthService.signIn();
  }

  createAccount() {
    this.dvAuthService.register();
  }
}

import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFi from '@angular/common/locales/fi';
import localeNo from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localeSv from '@angular/common/locales/sv';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import {
  AuthGuard,
} from './guards/auth/auth.guard';
import { ClientService } from './client.service';
import { CommmonComponentModule } from './common-components/common-component.module';
import { IntercomService } from './IntercomService';
import { LayerService } from './layer.service';
import { LoginComponent } from './login/login.component';
import { MatrialModule } from './matrial.module';
import { OauthDialogComponent } from './oAuth/oauth-dialog.component';
import { OAuthService } from './oAuth/oauth.service';
import { ReceiversModule } from './receivers/receivers.module';
import { FieldExportComponent } from './field-export/field-export.component';
import { SelectFieldComponent } from './field-export/select-field/select-field.component';
import { UrlService } from './services/url.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  DvToolbarMsalModule,
  DvToolbarMsalModuleOptions,
  DvToolbarTranslateModule,
  DvToolbarTranslateService,
  DvTranslatePipe,
  localeFactory,
  localeIdFactory
} from '@dv/toolbar-msal';
import { lastValueFrom } from 'rxjs';

registerLocaleData(localeSv);
registerLocaleData(localeFi);
registerLocaleData(localeNl);
registerLocaleData(localeNo);

export function initTranslate(
  translate: DvToolbarTranslateService
): () => Promise<void> {
  return async () => lastValueFrom(translate.init());
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OauthDialogComponent,
    SelectFieldComponent,
    FieldExportComponent,
  ],
  imports: [
    DvTranslatePipe,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatrialModule,
    CommmonComponentModule,
    ReceiversModule,
    FlexLayoutModule,
    HttpClientModule,
    AppRoutingModule,
    MatSortModule,
    DvToolbarTranslateModule.forChild({
      appName: environment.appName,
      debug: !environment.production,
      defaultLanguage: environment.defaultLanguage,
      translateUrl: environment.dvApiUrl,
    }),
    DvToolbarMsalModule.forRoot({
      appKey: environment.appKey,
      defaultLanguage: environment.defaultLanguage,
      dvApiUrl: environment.dvApiUrl,
      iframeProxyUrl: environment.iframeProxyUrl,
      production: environment.production,
      redirectUri: environment.redirectUri,
      staging: environment.staging,
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: localeFactory,
      multi: true,
      deps: [DvToolbarMsalModuleOptions],
    },
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [APP_INITIALIZER],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslate,
      deps: [DvToolbarTranslateService],
      multi: true,
    },
    DatePipe,
    ClientService,
    LayerService,
    OAuthService,
    AuthGuard,
    IntercomService,
    UrlService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

<dv-wizard #wizard [activeStep]="currStepp">
  <dv-wizardstep [title]="'_common_agricultural_unit' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-brukenhet (selectedBruk)="selectedBruk($event)"></dv-brukenhet>
    </div>
    <mat-card>
      <button
        style="float: right"
        [disabled]="selectedBrukningsenheter.length === 0"
        mat-raised-button
        (click)="wizard.activeStep = 2"
        color="primary"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="goToStart()"><mat-icon>arrow_left</mat-icon></button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_year' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-year (selectedYear)="selectedYear($event)"></dv-year>
    </div>
    <mat-card>
      <button
        style="float: right"
        mat-raised-button
        (click)="wizard.activeStep = 3"
        color="primary"
        [disabled]="year === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="wizard.activeStep = 1">
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_parcels' | dvt">
    <div style="flex: auto; display: flex; flex-direction: column; overflow: auto; align-items: center">
      <dv-skifte
        (selectedSkiften)="selectedSkifteId = $event"
        [eu]="true"
        [brukenheter]="selectedBrukningsenheter"
        [year]="year"
      ></dv-skifte>
    </div>
    <mat-card>
      <button
        style="float: right"
        mat-raised-button
        (click)="wizard.activeStep = 4"
        color="primary"
        [disabled]="year === 0"
      >
        {{ "_common_next" | dvt }}
      </button>
      <button style="float: right" mat-raised-button (click)="wizard.activeStep = 2">
        {{ "_common_previous" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
  <dv-wizardstep [title]="'_common_export' | dvt">
    <div style="align-items: center; display: flex; flex-direction: column; overflow: auto; flex: auto">
      <h2 style="text-align: center">{{ "_common_soon_done" | dvt }}</h2>
      <p style="text-align: center">{{ "_common_fill_information_and_download" | dvt }}</p>
      <form #euForm="ngForm">
        <div style="padding-top: 10px" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field style="width: 300px; margin-right: 10px">
            <input
              matInput
              required
              [(ngModel)]="kundb"
              title="{{ '_export_sam_client_county_letter' | dvt }}"
              placeholder="{{ '_export_sam_client_county_letter' | dvt }}"
              name="bok"
            />
          </mat-form-field>
          <mat-form-field style="width: 300px">
            <input
              matInput
              required
              [(ngModel)]="kunds"
              title="{{ '_export_sam_client_county_number' | dvt }}"
              placeholder="{{ '_export_sam_client_county_number' | dvt }}"
              name="sif"
            />
          </mat-form-field>
        </div>
        <div style="padding-top: 10px" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field style="margin-right: 10px">
            <input
              matInput
              required
              [(ngModel)]="org"
              title="{{ '_common_orgnumber' | dvt }}"
              placeholder="{{ '_common_orgnumber' | dvt }}"
              name="orgnummer"
            />
          </mat-form-field>
          <mat-form-field style="width: 60px; margin-left: 5px">
            <input matInput required [(ngModel)]="adId" title="Suffix" placeholder="Suffix" name="adid" />
          </mat-form-field>
        </div>
        <div style="padding-top: 10px" fxLayout="row" fxLayoutAlign="start center">
          <mat-checkbox
            [(ngModel)]="inklArea"
            title="{{ '_common_include_field_boundaries' | dvt }}"
            name="inklarea"
            align="start"
            >{{ "_common_include_field_boundaries" | dvt }}</mat-checkbox
          >
        </div>
      </form>
      <div class="export-buttons" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="center">
        <div fxLayout="column" fxLayout.xs="row">
          <button mat-raised-button color="accent" disabled="{{ !euForm.valid }}" (click)="export()">
            {{ "_common_download" | dvt }}
          </button>
        </div>
      </div>
    </div>
    <mat-card>
      <button style="float: right" mat-raised-button (click)="goToStart()">
        <mat-icon>arrow_left</mat-icon>
        {{ "_common_back_to_start" | dvt }}
      </button>
    </mat-card>
  </dv-wizardstep>
</dv-wizard>

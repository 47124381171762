import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../../../client.service';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';
import { DownloadFileStatusComponent } from '../../../common-components/download-file-status/download-file-status.component';
import { DvMapLegendComponent } from '../../../common-components/map-legend/dv-map-legend.component';
import { DvMapComponent } from '../../../common-components/map/dv-map.component';
import { WizardComponent } from '../../../common-components/wizard-component/WizardComponent';
import { LayerService } from '../../../layer.service';
import { UserFileModel } from '../../../models/FileModel';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-yara-tilldel',
  templateUrl: './yara-tilldel.component.html',
  styleUrls: ['./yara-tilldel.component.scss'],
})
export class YaraTilldelComponent implements AfterViewInit {
  currStepp: number = undefined;
  selectedFiles: UserFileModel[] = [];
  showLegend = true;

  private progressDialog: MatDialogRef<DownloadFileStatusComponent> = null;

  @ViewChild(WizardComponent, { static: true })
  private wizard: WizardComponent;

  currentFile: UserFileModel;

  @ViewChild(DvMapComponent, { static: true })
  private map: DvMapComponent;

  @ViewChild(DvMapLegendComponent, { static: true })
  private legend: DvMapLegendComponent;

  constructor(
    private clientService: ClientService,
    private layerService: LayerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: DvToolbarTranslateService
  ) {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }

  ngAfterViewInit() {
    this.layerService.$downloadProgress.subscribe((progress) => {
      if (progress < 100) {
        if (!this.progressDialog) {
          this.progressDialog = this.dialog.open(DownloadFileStatusComponent, {
            data: progress,
          });
          this.progressDialog.afterClosed().subscribe(() => {
            this.progressDialog = null;
          });
        }
        this.progressDialog.componentInstance.data = progress;
      } else {
        if (this.progressDialog) {
          this.progressDialog.close();
        }
      }
    });
  }

  viewFile(file: UserFileModel) {
    window.setTimeout(() => {
      this.currentFile = file;
      this.map.reRender();
      this.map.clearMapSource();
      this.legend.setFile(file);
      this.map.setMapSource(file.name, file.fc);
      window.dispatchEvent(new Event('resize'));
    });
  }

  setStepPreview() {
    this.layerService
      .download(this.selectedFiles)
      .subscribe(
        (res) => {
          this.wizard.activeStep = 2;
          this.viewFile(res[0]);
        },
        (error) => {
          this.snackBar.open(
            this.translateService.t(error),
            this.translateService.t('Stäng')
          );
        }
      )
      .add(() => {
        this.progressDialog.close();
        this.progressDialog = null;
      });
  }

  export(target: string) {
    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.upload(
        this.selectedFiles.map((f) => {
          f.fc['legend'] = f.name;
          return f.fc;
        }),
        target
      ),
      disableClose: true,
    });
  }

  goToStart() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.router.navigate(['client', params['clientid']]);
    });
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { FileUploadResultModel } from '../../models/FileUploadResultModel';
import { Exception, UnknownException } from '@dv/insights';

@Component({
    selector: 'dv-operation-done-dialog',
    templateUrl: './operation-done-dialog.component.html',
    styleUrls: ['./operation-done-dialog.component.scss'],
})
export class OperationDoneDialogComponent implements OnInit {
    constructor(
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: Observable<FileUploadResultModel>
    ) {}

    fileRes: FileUploadResultModel = null;
    errorRes: Exception = null;

    ngOnInit() {
        this.data.subscribe(
            (fileRes) => {
                this.fileRes = fileRes;
                if (this.fileRes.uri) {
                    window.open(this.fileRes.uri, '_blank');
                }
                this.cd.markForCheck();
            },
            (error: Exception) => {
                if (error) {
                    this.errorRes = error;
                } else {
                    this.errorRes = new UnknownException();
                }
                this.cd.markForCheck();
            }
        );
    }
}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ClientService } from '../../../client.service';
import { LoggerModel } from '../../../models/Models';
import { Receiver } from '../../receiver';

@Component({
  selector: 'dv-futurefarm-logmaster-export',
  templateUrl: './futurefarm-logmaster-export.component.html',
  styleUrls: ['./futurefarm-logmaster-export.component.scss'],
})
export class FuturefarmLogmasterExportComponent
  extends Receiver
  implements OnInit {
  currStepp = 0;
  loggers: LoggerModel[] = [];
  links: ExportRes[] = [];
  from: string;
  to: string;
  modeWork = false;
  modeRaw = true;
  exportDone = true;

  includeWKT = false;
  includeField = false;
  includeSkifte = false;
  includeZone = false;


  isSkira = window.location.href.indexOf('skira') !== -1;

  constructor(
    private cd: ChangeDetectorRef,
    ar: ActivatedRoute,
    r: Router,
    private clientService: ClientService
  ) {
    super(ar, r);
  }

  ngOnInit() { }

  doneLinks() {
    if (this.links.length === 0) {
      return 0;
    }
    return (
      (this.links.filter(function (l) {
        return l.done;
      }).length /
        this.links.length) *
      100
    );
  }
  setLoggers(loggers) {
    this.loggers = loggers;
    this.cd.detectChanges();
  }
  export() {
    this.links = [];
    this.exportDone = false;

    forkJoin(
      this.loggers.map((l) => {
        const link: ExportRes = {
          done: false,
          id: l.maskinId,
          name: l.maskinNamn,
          url: '',
        };
        this.links.push(link);


        if (this.isSkira) {
          return this.clientService
            .exportLogmasterToSkira(
              this.modeWork ? 'work' : 'raw',
              l.maskinId,
              new Date(this.from).toJSON(),
              new Date(this.to).toJSON(),
              this.includeField,
              this.includeSkifte,
              this.includeZone,
              this.includeWKT
            )
            .pipe(
              map((url) => {
                link.done = true;
                this.cd.detectChanges();
                return true;
              }),
              catchError((err) => {
                link.done = true;
                this.cd.detectChanges();
                return of(false);
              })
            );
        }

        return this.clientService
          .exportLogmaster(
            this.modeWork ? 'work' : 'raw',
            l.maskinId,
            new Date(this.from).toJSON(),
            new Date(this.to).toJSON(),
            this.includeField,
            this.includeSkifte,
            this.includeZone,
            this.includeWKT
          )
          .pipe(
            map((url) => {
              link.done = true;
              link.url = url;
              this.cd.detectChanges();
              return true;
            }),
            catchError((err) => {
              link.done = true;
              this.cd.detectChanges();
              return of(false);
            })
          );
      })
    ).subscribe(() => {
      this.exportDone = true;
      this.cd.detectChanges();
    });
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
}

interface ExportRes {
  id: number;
  done: boolean;
  name: string;
  url: string;
}

import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ClientService } from '../../client.service';
import { FileType, GeoFile } from '../../models/FileModel';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss'],
})
export class SelectFileComponent implements OnInit {
  files: GeoFile[] = [];
  filesDatasource = new MatTableDataSource<GeoFile>(this.files);
  _selectedFiles: GeoFile[] = [];
  selection = new SelectionModel<GeoFile>(true, []);
  loaded = false;

  @Output() selectedFiles = new EventEmitter<GeoFile[]>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: DvToolbarTranslateService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.selection.changed.subscribe((res) => {
      this.selectedFiles.emit(this.selection.selected);
    });

    this.activatedRoute.pathFromRoot[1].params.subscribe(
      (p) => {
        this.clientService
          .getFiles(<number>p.clientid, FileType.Tilldelning)
          .subscribe((files) => {
            this.filesDatasource = new MatTableDataSource<GeoFile>(files);
            this.filesDatasource.sort = this.sort;
            this.filesDatasource.sortingDataAccessor = (item, property) => {
              switch (property) {
                case 'created':
                  return this.getCreated(item);
                default:
                  return item[property];
              }
            };

            this.loaded = true;
          });
      },
      (err) => {
        this.snackBar.open(err, this.translateService.t('Stäng'));
      }
    );
  }

  getCreated(row: GeoFile): string {
    let createdDate = row.metadata.find(data => data.key === 'Created')?.value;
    if(createdDate){
      createdDate = this.datepipe.transform(createdDate, "yyyy-MM-dd");
    }
    else{
      createdDate = "-"
    }
    return createdDate;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filesDatasource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.filesDatasource.data.forEach((row) => this.selection.select(row));
  }
}

export class FileDataSource extends DataSource<GeoFile> {
  disconnect(collectionViewer: CollectionViewer): void {}

  constructor(private clientService: ClientService, private clientId: number) {
    super();
  }

  connect(): Observable<GeoFile[]> {
    return this.clientService.getFiles(this.clientId, FileType.Tilldelning);
  }
}

<h2>{{ "_export_select_information_type" | dvt }}</h2>
<p style="text-align: center">{{ "_export_click_information_type" | dvt }}</p>

<div class="export-buttons">
  <div *ngIf="skiften" fxLayout="column" fxLayout.xs="row">
    <a
      style="background-color: #fff; border: 1px solid lightgray"
      mat-button
      routerLink="../field"
      class="export-button"
    >
      <div class="content-wrapper" fxLayout="column">
        <img src="/assets/skiften-icon.svg" alt="" />
        <span class="export-text">{{ "_common_parcels" | dvt }}</span>
      </div>
    </a>
  </div>

  <div *ngIf="tilldelning" fxLayout="column" fxLayout.xs="row">
    <a
      style="background-color: #fff; border: 1px solid lightgray"
      mat-button
      routerLink="../tilldel"
      class="export-button"
    >
      <div class="content-wrapper" fxLayout="column">
        <img src="/assets/files-icon.svg" alt="" />
        <span class="export-text">{{ "_common_allocation_file" | dvt }}</span>
      </div>
    </a>
  </div>

  <!-- ****SCB***** -->
  <div *ngIf="scb" fxLayout="column" fxLayout.xs="row">
    <a
      style="background-color: #fff; border: 1px solid lightgray"
      mat-button
      routerLink="../fertilizer"
      class="export-button"
    >
      <div class="content-wrapper" fxLayout="column">
        <img src="/assets/godsel.svg" alt="" />
        <span class="export-text">{{ "_common_fertilizer" | dvt }}</span>
      </div>
    </a>
  </div>

  <div *ngIf="scb" fxLayout="column" fxLayout.xs="row">
    <a
      style="background-color: #fff; border: 1px solid lightgray"
      mat-button
      routerLink="../spraying"
      class="export-button"
    >
      <div class="content-wrapper" fxLayout="column">
        <img src="/assets/vaxtskydd.svg" alt="" />
        <span class="export-text">{{ "_common_crop_protection" | dvt }}</span>
      </div>
    </a>
  </div>
  <!-- ******* -->

  <!-- FutureFarm -->
  <div *ngIf="futurefarm" fxLayout="column" fxLayout.xs="row">
    <a
      style="background-color: #fff; border: 1px solid lightgray"
      mat-button
      routerLink="../cropplan"
      class="export-button"
    >
      <div class="content-wrapper" fxLayout="column">
        <img src="/assets/icon-croppplan.svg" alt="" />
        <span class="export-text">CropPLAN</span>
      </div>
    </a>
  </div>

  <div *ngIf="futurefarm" fxLayout="column" fxLayout.xs="row">
    <a
      style="background-color: #fff; border: 1px solid lightgray"
      mat-button
      routerLink="../logmaster"
      class="export-button"
    >
      <div class="content-wrapper" fxLayout="column">
        <img src="/assets/Icon-LogMaster.svg" alt="" />
        <span class="export-text">LogMASTER</span>
      </div>
    </a>
  </div>
</div>

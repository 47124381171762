import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScbComponent } from './scb/scb.component';
import { SamInternetComponent } from './sam-internet/sam-internet.component';
import { TrimbleComponent } from './trimble/trimble.component';
import { TrimbleConnectComponent } from './trimble-connect/trimble-connect.component';
import { MyJohnDeereComponent } from './my-john-deere/my-john-deere.component';
import { JohnDeereComponent } from './john-deere/john-deere.component';
import { KvernelandComponent } from './kverneland/kverneland.component';
import { YaraComponent } from './yara/yara.component';
import { ShapeComponent } from './shape/shape.component';
import { ReceiverSelectionComponent } from './receiver-selection.component';
import { RouterModule } from '@angular/router';
import { MatrialModule } from '../matrial.module';
import { JohnDeereTilldelComponent } from './john-deere/john-deere-tilldel/john-deere-tilldel.component';
import { BlankComponent } from './blank.component';
import { ScbSprayingComponent } from './scb/scb-spraying/scb-spraying.component';
import { ScbFertilizerComponent } from './scb/scb-fertilizer/scb-fertilizer.component';
import { CommmonComponentModule } from '../common-components/common-component.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MyJohnDeereFieldComponent } from './my-john-deere/my-john-deere-field/my-john-deere-field.component';
import { MyJohnDeereTilldelComponent } from './my-john-deere/my-john-deere-tilldel/my-john-deere-tilldel.component';
import { FormsModule } from '@angular/forms';
import { YaraTilldelComponent } from './yara/yara-tilldel/yara-tilldel.component';
import { ShapeTilldelComponent } from './shape/shape-tilldel/shape-tilldel.component';
import { KvernelandTilldelComponent } from './kverneland/kverneland-tilldel/kverneland-tilldel.component';
import { TrimbleTilldelComponent } from './trimble/trimble-tilldel/trimble-tilldel.component';
import { SamInternetFieldComponent } from './sam-internet/sam-internet-field/sam-internet-field.component';
import { TrimbleFieldComponent } from './trimble/trimble-field/trimble-field.component';
import { VodkaExportComponent } from './vodka/vodka-export/vodka-export.component';
import { HushallningssallskapetLonsamGrodaComponent } from './hushallningssallskapet/hushallningssallskapet-lonsamgroda/hushallningssallskapet-lonsamgroda.component';
import { FuturefarmLogmasterExportComponent } from './futurefarm/logmaster-export/futurefarm-logmaster-export.component';
import { FuturefarmCropplanExportComponent } from './futurefarm/cropplan-export/futurefarm-cropplan-export.component';
import { FuturefarmComponent } from './futurefarm/futurefarm.component';
import { PipesModule } from '../pipes/pipes.module';
import { MaintenanceLandingComponent } from './my-john-deere/maintenance-landing-page/maintenance-landing-page.component';
import { SkiraComponent } from './skira/skira.component';
import { DvTranslatePipe } from '@dv/toolbar-msal';
import { CropplanExportComponent } from './cropplan-export/cropplan-export.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatrialModule,
    CommmonComponentModule,
    FlexLayoutModule,
    FormsModule,
    PipesModule,
    DvTranslatePipe,
  ],
  declarations: [
    ScbComponent,
    SamInternetComponent,
    TrimbleComponent,
    TrimbleConnectComponent,
    MyJohnDeereComponent,
    JohnDeereComponent,
    KvernelandComponent,
    YaraComponent,
    ShapeComponent,
    ReceiverSelectionComponent,
    MyJohnDeereComponent,
    MyJohnDeereFieldComponent,
    JohnDeereTilldelComponent,
    BlankComponent,
    ScbSprayingComponent,
    ScbFertilizerComponent,
    MyJohnDeereTilldelComponent,
    YaraTilldelComponent,
    ShapeTilldelComponent,
    KvernelandTilldelComponent,
    TrimbleTilldelComponent,
    SamInternetFieldComponent,
    TrimbleFieldComponent,
    VodkaExportComponent,
    HushallningssallskapetLonsamGrodaComponent,
    FuturefarmLogmasterExportComponent,
    FuturefarmCropplanExportComponent,
    FuturefarmComponent,
    MaintenanceLandingComponent,
    SkiraComponent,
    CropplanExportComponent
  ],
})
export class ReceiversModule {}

import { Component, OnInit, NgZone } from '@angular/core';
import { ClientService } from '../client.service';
import { Countries, Provider, Icons } from '../models/app/Provider';
import { AnvandareModel } from '../models/Models';
import { DvClientService } from '@dv/toolbar-msal';
import { take } from 'rxjs';

@Component({
  selector: 'dv-receiver-selection',
  templateUrl: './receiver-selection.component.html',
  styleUrls: ['./receiver-selection.component.scss'],
})
export class ReceiverSelectionComponent implements OnInit {
  constructor(
    private client: ClientService,
    private zone: NgZone,
    private dvClientService: DvClientService
  ) { }
  userId: number;
  clientId: number;
  dataLoaded = false;
  showHushallningssallskapet = false;
  showCropplan = false;

  integrations: Provider[] = [];

  ngOnInit() {
    this.client.getUser().subscribe((res) => {
      this.zone.run(() => {
        this.setData(res);

        if (this.showHushallningssallskapet) {
          this.integrations.push({
            fullname: 'HS Lönsam gröda',
            shortname: 'hs',
            country: Countries.se,
            image: Icons.hushallningssallskapet,
            route: 'hushallningssallskapet',
            color: '#c8e5a5',
            category: 'info',
          });
        }

        if (this.showCropplan) {
          this.integrations.push( {
            fullname: 'Historisk växtodlingsdata',
            shortname: 'cropplandata',
            country: Countries.se,
            image: Icons.cropplandata,
            route: 'cropplandata',
            color: '#C8E6C9',
            category: 'info',
          },);
        }
        this.integrations = [...this.integrations];
      });
    });

    this.integrations.push(
      {
        fullname: 'Trimble',
        shortname: 'trimble',
        country: Countries.all,
        image: Icons.trimble,
        route: 'trimble',
        color: '#E3F2FD',
        category: 'geo',
      },
      {
        fullname: 'My John Deere Portal',
        shortname: 'myjohndeere',
        country: Countries.all,
        image: Icons.myjohndeere,
        route: 'myjohndeere',
        color: '#DCEDC8',
        category: 'geo',
      },
      {
        fullname: 'John Deere',
        shortname: 'johndeere',
        country: Countries.all,
        image: Icons.johndeere,
        route: 'johndeere',
        color: '#DCEDC8',
        category: 'geo',
      },
      {
        fullname: 'Yara',
        shortname: 'yara',
        country: Countries.all,
        image: Icons.yara,
        route: 'yara',
        color: '#90CAF9',
        category: 'geo',
      },
      {
        fullname: 'Shape File',
        shortname: 'shape',
        country: Countries.all,
        image: Icons.shape,
        route: 'shape',
        color: '#C8E6C9',
        category: 'geo',
      },
      {
        fullname: 'Kverneland',
        shortname: 'kverneland',
        country: Countries.all,
        image: Icons.kverneland,
        route: 'kverneland',
        color: '#F0F4C3',
        category: 'geo',
      },
      /*{
        fullname: 'Future Farm',
        shortname: 'futurefarm',
        country: Countries.all,
        image: Icons.futurefarm,
        route: 'futurefarm',
        color: '#c8e5a5',
        category: 'info',
      }*/
    );

    this.dvClientService.client$
      .pipe(take(1))
      .subscribe(client => {
        const COUNTRY_CODE = client.land;
        this.clientId = client.id;
        this.client.setClient(client.id);

        switch (COUNTRY_CODE) {
          case 'se':
            this.integrations.push(
              /*{
                fullname: 'Statistiska centralbyrån',
                shortname: 'scb',
                country: Countries.se,
                image: Icons.scb,
                route: 'scb',
                color: '#f9f9f9',
                category: 'info',
              },*/
              {
                fullname: 'SAM-Internet',
                shortname: 'sam',
                country: Countries.se,
                image: Icons.sam,
                route: 'sam',
                color: '#FEDDBA',
                category: 'info',
              },
             
              /*{
                fullname: 'Absolut Company',
                shortname: 'absolut',
                country: Countries.se,
                image: Icons.absolut,
                route: 'absolut/export',
                color: '#ffffff',
                category: 'info',
              },*/
              /* {
               fullname: 'Skira',
                shortname: 'skira',
                country: Countries.se,
                image: Icons.skira,
                route: 'skira',
                color: '#00c28347',
                category: 'info',
              } */
            );
            break;
        }

        this.integrations = [...this.integrations];
      });
  }

  initIntegration(providerShortName: string): void {
    if (providerShortName !== 'myjohndeere') {
      return;
    }

    if (!this.dataLoaded) {
      this.client.getUser().subscribe((res) => {
        this.setData(res);
      });
    }

    this.client.setClient(this.clientId);

    this.client
      .isUserAuthenticated(providerShortName, this.userId)
      .subscribe((res) => {
        if (res !== null && res !== undefined) {
          location.href = res;
        }
      });
  }

  private setData(res: AnvandareModel) {
    if (res.anvId > 0) {
      this.userId = res.anvId;

      if (res.organisationer) {
        res.organisationer.forEach((org) => {
          if (org.keyName === 'hushallningssallskapet') {
            this.showHushallningssallskapet = true;
          }
          if (org.keyName === 'exportcropplandata') {
            this.showCropplan = true;
          }
        });
      }
    }

    this.dataLoaded = true;
  }
}

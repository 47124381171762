import { ActivatedRoute, Router } from '@angular/router';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import { ClientService } from '../../../client.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  BrukningsenhetModel,
  SkifteDetielsModel,
  SkordModel,
  TabellGodselModel,
  TabellUtsadeModel,
  TabellVaxtskyddModel,
} from '../../../models/Models';

@Component({
  selector: 'dv-hushallningssallskapet-lonsamgroda',
  templateUrl: './hushallningssallskapet-lonsamgroda.component.html',
  styleUrls: ['./hushallningssallskapet-lonsamgroda.component.scss'],
})
export class HushallningssallskapetLonsamGrodaComponent implements OnInit {
  currStepp: number = undefined;
  selectedBrukningsenheter: BrukningsenhetModel[] = [];
  selectedSkifteId: number[] = [];
  year = 0;
  allSkiften: SkifteDetielsModel[] = [];
  tabellUtsade: TabellUtsadeModel[] = [];
  tabellGodsel: TabellGodselModel[] = [];
  tabellVaxtskydd: TabellVaxtskyddModel[] = [];

  readonly CROP_HEADER_ROW: number = 6;
  readonly GODSEL_HEADER_ROW: number = 25;
  readonly VAXTSKYDD_HEADER_ROW: number = 41;
  readonly MAX_GODSEL: number = 12;

  columnCount = 15;
  vaxtskyddOffset = 0;
  clientName = '';

  constructor(
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);

      const currentClient = this.clientService.getClient();
      this.clientService.getClients().subscribe((clients) => {
        clients.forEach((c) => {
          if (c.id == currentClient) {
            this.clientName = c.namn;
          }
        });
      });
    });
  }

  ngOnInit() {}

  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
    this.cd.detectChanges();
  }

  selectedYear(ev) {
    this.year = ev;

    this.clientService.getSkiften(this.year).subscribe((skiften) => {
      this.allSkiften = skiften;
    });

    this.clientService.getTabellUtsade(this.year).subscribe((tabellUtsade) => {
      this.tabellUtsade = tabellUtsade;
    });

    this.clientService.getTabellGodsel(this.year).subscribe((tabellGodsel) => {
      this.tabellGodsel = tabellGodsel;
    });

    this.clientService
      .getTabellVaxtskydd(this.year)
      .subscribe((tabellVaxtskydd) => {
        this.tabellVaxtskydd = tabellVaxtskydd;
      });
  }

  generateExcelfile() {
    const utsade = this.getUtsadeObject();
    const godsel = this.getGodselObject();
    const vaxtskydd = this.getVaxtskyddObject();
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Lönsam gröda');

    this.AddUtsade(sheet, utsade);
    this.addGodsel(sheet, godsel);
    this.addVaxtskydd(sheet, vaxtskydd);

    sheet.mergeCells('G2:K2');
    const headerCell = sheet.getCell('G2');
    headerCell.value = this.clientName;
    headerCell.font = { bold: true, size: 20 };

    for (let i = 1; i <= this.columnCount; i++) {
      if (i % 2 !== 0) {
        const col = sheet.getColumn(String.fromCharCode('B'.charCodeAt(0) + i));
        col.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
          if (rowNumber > 4) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'CCCCCCCC' },
            };

            if (col.letter === 'C') {
              cell.border = { left: { style: 'thin' } };
            }
            if (col.letter === 'C' && rowNumber === 6) {
              cell.border = {
                left: { style: 'thin' },
                bottom: { style: 'thick' },
              };
            }

            if (
              col.letter === 'C' &&
              rowNumber === this.GODSEL_HEADER_ROW - 1
            ) {
              cell.border = {
                left: { style: 'thin' },
                bottom: { style: 'thin' },
              };
            }

            if (
              col.letter === 'C' &&
              rowNumber === this.VAXTSKYDD_HEADER_ROW + this.vaxtskyddOffset - 1
            ) {
              cell.border = {
                left: { style: 'thin' },
                bottom: { style: 'thin' },
              };
            }
          }
        });
      }
    }

    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(
          new Blob([buffer]),
          `Lönsam gröda_${
            this.clientName
          }_${new Date().toLocaleDateString()}.xlsx`
        )
      );
  }

  private addVaxtskydd(sheet: any, vaxtskydd: {}) {
    sheet.getCell('A' + (40 + this.vaxtskyddOffset)).value =
      'Bekämpningsmedel & Mikronäring';
    sheet.getCell('A' + (40 + this.vaxtskyddOffset)).font = { bold: true };
    sheet.getCell('A' + (41 + this.vaxtskyddOffset)).value = 'Preparat';
    sheet.getCell('A' + (41 + this.vaxtskyddOffset)).font = { bold: true };
    sheet.getCell('B' + (41 + this.vaxtskyddOffset)).value = 'Pris, kr/kg';
    sheet.getCell('B' + (41 + this.vaxtskyddOffset)).font = { bold: true };

    let vaxtskyddStartRow =
      this.VAXTSKYDD_HEADER_ROW + this.vaxtskyddOffset + 1;

    for (const v in vaxtskydd) {
      let count = 0;
      let totPrice = 0;
      sheet.getCell('A' + vaxtskyddStartRow).value = v;
      vaxtskydd[v].forEach((vu: ExportHSVaxtskydd) => {
        const col = sheet.getColumn(vu.cropKey);
        sheet.getCell(
          col.letter + (this.VAXTSKYDD_HEADER_ROW + this.vaxtskyddOffset)
        ).value = col['groda'];
        const cellVVAlue = sheet.getCell(col.letter + vaxtskyddStartRow);
        const areal = sheet.getCell(col.letter + 7).value;
        cellVVAlue.value =
          Math.round((vu.totMangd.reduce((a, b) => a + b) / areal) * 100) / 100;
        cellVVAlue.alignment = { vertical: 'middle', horizontal: 'center' };

        const sumtotPris = vu.totPris.reduce((a, b) => a + b);
        const sumtotMangd = vu.totMangd.reduce((a, b) => a + b);

        if (sumtotPris > 0 && sumtotMangd > 0) {
          totPrice += sumtotPris / sumtotMangd;
        }

        count++;
      });

      const cellVPrice = sheet.getCell('B' + vaxtskyddStartRow);
      cellVPrice.value =
        count > 0 ? Math.round((totPrice / count) * 100) / 100 : 0;
      cellVPrice.alignment = { vertical: 'middle', horizontal: 'center' };
      vaxtskyddStartRow++;
    }

    for (let i = 1; i <= this.columnCount; i++) {
      const cell = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) +
          (this.VAXTSKYDD_HEADER_ROW + this.vaxtskyddOffset - 1)
      );
      cell.value = i;
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = { bottom: { style: 'thin' } };
    }

    // Add crop headers to godsel
    for (let i = 1; i <= this.columnCount; i++) {
      const cellCrop = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) + this.CROP_HEADER_ROW
      );

      const cellVaxtskydd = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) +
          (this.VAXTSKYDD_HEADER_ROW + this.vaxtskyddOffset)
      );

      cellVaxtskydd.value = cellCrop.value;
      cellVaxtskydd.font = { bold: true };
      cellVaxtskydd.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  }

  private addGodsel(sheet: any, godsel: {}) {
    sheet.getCell('A24').value = 'Gödning & Kalk';
    sheet.getCell('A24').font = { bold: true };
    sheet.getCell('A25').value = 'Gödselslag';
    sheet.getCell('A25').font = { bold: true };
    sheet.getCell('B25').value = 'Pris, kr/kg';
    sheet.getCell('B25').font = { bold: true };

    let godselStartRow = this.GODSEL_HEADER_ROW + 1;
    let totGodselCount = 0;

    for (const g in godsel) {
      totGodselCount++;
      let count = 0;
      let totPrice = 0;
      sheet.getCell('A' + godselStartRow).value = g;
      godsel[g].forEach((gu: ExportHSGodsel) => {
        const col = sheet.getColumn(gu.cropKey);
        sheet.getCell(col.letter + this.GODSEL_HEADER_ROW).value = col['groda'];
        const cellGodselValue = sheet.getCell(col.letter + godselStartRow);
        const areal = sheet.getCell(col.letter + 7).value;
        cellGodselValue.value =
          Math.round((gu.totMangd.reduce((a, b) => a + b) / areal) * 100) / 100;
        cellGodselValue.alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };

        const sumtotPris = gu.totPris.reduce((a, b) => a + b);
        const sumtotMangd = gu.totMangd.reduce((a, b) => a + b);

        if (sumtotPris > 0 && sumtotMangd > 0) {
          totPrice += sumtotPris / sumtotMangd;
        }
        count++;
      });
      const cellGodselPrice = sheet.getCell('B' + godselStartRow);
      cellGodselPrice.value =
        count > 0 ? Math.round((totPrice / count) * 100) / 100 : 0;
      cellGodselPrice.alignment = { vertical: 'middle', horizontal: 'center' };
      godselStartRow++;
    }

    // Set offsetvalue to move down 'vaxtskydd'
    if (totGodselCount > this.MAX_GODSEL) {
      this.vaxtskyddOffset = totGodselCount - this.MAX_GODSEL;
    }

    for (let i = 1; i <= this.columnCount; i++) {
      const cell = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) +
          (this.GODSEL_HEADER_ROW - 1)
      );
      cell.value = i;
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = { bottom: { style: 'thin' } };
    }

    // Add crop headers to godsel
    for (let i = 1; i <= this.columnCount; i++) {
      const cellCrop = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) + this.CROP_HEADER_ROW
      );
      const cellGodsel = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) + this.GODSEL_HEADER_ROW
      );
      cellGodsel.value = cellCrop.value;
      cellGodsel.font = { bold: true };
      cellGodsel.alignment = { vertical: 'middle', horizontal: 'center' };
    }
  }

  private AddUtsade(sheet: any, utsade: {}) {
    sheet.getCell('A6').value = 'Gröda';
    sheet.getCell('A6').font = { bold: true };
    sheet.getCell('A6').border = { bottom: { style: 'thick' } };
    sheet.getCell('B6').border = { bottom: { style: 'thick' } };

    sheet.getCell('A7').value = 'Areal';
    sheet.getCell('B7').value = 'ha';
    sheet.getCell('A10').value = 'Utsädessort';
    sheet.getCell('A10').font = { bold: true };
    sheet.getCell('A11').value = '\tUtsädesmängd';
    sheet.getCell('B11').value = 'kg/ha';
    sheet.getCell('A12').value = '\tPris utsäde';
    sheet.getCell('B12').value = 'kr/kg';
    sheet.getCell('A13').value = 'Fånggröda, sort';
    sheet.getCell('A14').value = '\tUtsädesmängd fånggröda';
    sheet.getCell('B14').value = 'kg/ha';
    sheet.getCell('A15').value = '\tPris utsäde fånggröda';
    sheet.getCell('B15').value = 'kr/kg';
    sheet.getCell('A17').value = 'Skörd, torr vara';
    sheet.getCell('B17').value = 'kg/ha';
    sheet.getCell('A17').font = { bold: true };
    sheet.getCell('A18').value = 'Avräkningspris kr/kg';
    sheet.getCell('B18').value = 'kr/kg';
    sheet.getCell('A18').font = { bold: true };
    sheet.getCell('A19').value = 'Vattenhalt vid skörd, %';
    sheet.getCell('B19').value = '%';
    sheet.getCell('A20').value = 'Halm vikt';
    sheet.getCell('B20').value = 'kg/ha';
    sheet.getCell('A21').value = 'Halm pris';
    sheet.getCell('B21').value = 'kr/kg';
    sheet.getColumn('A').width = 33;
    sheet.getColumn('B').width = 10;

    let ui = 0;
    for (const u in utsade) {
      const colChar = String.fromCharCode('C'.charCodeAt(0) + ui);
      const col = sheet.getColumn(colChar);
      col.width = 13;
      col.key = utsade[u].cropKey;
      col['groda'] = utsade[u].groda;

      const cellGroda = sheet.getCell(colChar + 6);
      cellGroda.value = utsade[u].groda;
      cellGroda.font = { bold: true };
      cellGroda.alignment = { vertical: 'middle', horizontal: 'center' };

      const cellAreal = sheet.getCell(colChar + 7);
      cellAreal.value = Math.round(utsade[u].areal * 100) / 100;
      cellAreal.alignment = { vertical: 'middle', horizontal: 'center' };
      const cellSort = sheet.getCell(colChar + 10);
      cellSort.value = utsade[u].sort;
      cellSort.alignment = { vertical: 'middle', horizontal: 'center' };
      if (utsade[u].mangdUtsade && utsade[u].mangdUtsade.length > 0) {
        const utsadeMangd = sheet.getCell(colChar + 11);
        const areal = sheet.getCell(col.letter + 7).value;
        utsadeMangd.value =
          Math.round(
            (utsade[u].totMangdUtsade.reduce((a, b) => a + b) / areal) * 100
          ) / 100;
        utsadeMangd.alignment = { vertical: 'middle', horizontal: 'center' };
      }

      const utsadePris = sheet.getCell(colChar + 12);
      const sumMangd =
        utsade[u].totMangdUtsade && utsade[u].totMangdUtsade.length > 0
          ? utsade[u].totMangdUtsade.reduce((a, b) => a + b)
          : 0;
      const sumPris =
        utsade[u].totPrisUtsade && utsade[u].totPrisUtsade.length > 0
          ? utsade[u].totPrisUtsade.reduce((a, b) => a + b)
          : 0;
      utsadePris.value =
        sumMangd > 0 && sumPris > 0
          ? Math.round((sumPris / sumMangd) * 100) / 100
          : 0;
      utsadePris.alignment = { vertical: 'middle', horizontal: 'center' };

      if (utsade[u].mangdFanggroda && utsade[u].mangdFanggroda.length > 0) {
        const fangrodaMangd = sheet.getCell(colChar + 14);
        fangrodaMangd.value =
          Math.round(
            (utsade[u].mangdFanggroda.reduce((a, b) => a + b) /
              utsade[u].mangdFanggroda.length) *
              100
          ) / 100;
        fangrodaMangd.alignment = { vertical: 'middle', horizontal: 'center' };
      }

      const fanggrodaPris = sheet.getCell(colChar + 15);
      const sumMangdFg =
        utsade[u].totMangdFanggroda && utsade[u].totMangdFanggroda.length > 0
          ? utsade[u].totMangdFanggroda.reduce((a, b) => a + b)
          : 0;
      const sumPrisFg =
        utsade[u].totPrisFanggroda && utsade[u].totPrisFanggroda.length > 0
          ? utsade[u].totPrisFanggroda.reduce((a, b) => a + b)
          : 0;
      fanggrodaPris.value =
        sumMangdFg > 0 && sumPrisFg > 0
          ? Math.round((sumPrisFg / sumMangdFg) * 100) / 100
          : 0;
      fanggrodaPris.alignment = { vertical: 'middle', horizontal: 'center' };

      if (utsade[u].totMangdSkord && utsade[u].totMangdSkord.length > 0) {
        const skordMangd = sheet.getCell(colChar + 17);
        skordMangd.value = Math.round(
          utsade[u].totMangdSkord.reduce((a, b) => a + b) / utsade[u].areal
        );
        skordMangd.alignment = { vertical: 'middle', horizontal: 'center' };
      }

      const skordPris = sheet.getCell(colChar + 18);

      const sumMangdSkord =
        utsade[u].totMangdSkord && utsade[u].totMangdSkord.length > 0
          ? utsade[u].totMangdSkord.reduce((a, b) => a + b)
          : 0;
      const sumPrisSkord =
        utsade[u].totPrisSkkord && utsade[u].totPrisSkkord.length > 0
          ? utsade[u].totPrisSkkord.reduce((a, b) => a + b)
          : 0;
      skordPris.value =
        sumMangdSkord > 0 && sumPrisSkord > 0
          ? Math.round((sumPrisSkord / sumMangdSkord) * 100) / 100
          : 0;
      skordPris.alignment = { vertical: 'middle', horizontal: 'center' };

      if (utsade[u].vattenhaltSkord && utsade[u].vattenhaltSkord.length > 0) {
        const skordVH = sheet.getCell(colChar + 19);
        skordVH.value =
          Math.round(
            (utsade[u].vattenhaltSkord.reduce((a, b) => a + b) /
              utsade[u].vattenhaltSkord.length) *
              100
          ) /
            100 +
          '%';
        skordVH.alignment = { vertical: 'middle', horizontal: 'center' };
      }

      if (utsade[u].mangdHalm && utsade[u].mangdHalm.length > 0) {
        const halmMangd = sheet.getCell(colChar + 20);
        halmMangd.value =
          Math.round(
            (utsade[u].mangdHalm.reduce((a, b) => a + b) /
              utsade[u].mangdHalm.length) *
              100
          ) / 100;
        halmMangd.alignment = { vertical: 'middle', horizontal: 'center' };
      }

      const sumMangdHalm =
        utsade[u].totMangdHalm && utsade[u].totMangdHalm.length > 0
          ? utsade[u].totMangdHalm.reduce((a, b) => a + b)
          : 0;
      const sumPrisHalm =
        utsade[u].totPrisHalm && utsade[u].totPrisHalm.length > 0
          ? utsade[u].totPrisHalm.reduce((a, b) => a + b)
          : 0;
      const halmPris = sheet.getCell(colChar + 21);
      halmPris.value =
        sumMangdHalm > 0 && sumPrisHalm > 0
          ? Math.round((sumPrisHalm / sumMangdHalm) * 100) / 100
          : 0;
      halmPris.alignment = { vertical: 'middle', horizontal: 'center' };

      ui++;
    }

    if (ui + 1 > this.columnCount) {
      this.columnCount = ui + 1;
    }

    for (let i = 1; i <= this.columnCount; i++) {
      const cell = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) + (this.CROP_HEADER_ROW - 1)
      );
      cell.value = i;
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      const cell2 = sheet.getCell(
        String.fromCharCode('B'.charCodeAt(0) + i) + this.CROP_HEADER_ROW
      );
      cell2.border = { bottom: { style: 'thick' } };
    }
  }

  /**
   * Get a dictionary of 'gödsel'-data grouped on crop name
   */
  private getGodselObject() {
    const godselDict = {};
    const filteredSkiften = this.allSkiften.filter(
      (s) => this.selectedSkifteId.indexOf(s.id) >= 0
    );

    filteredSkiften.forEach((skifte) => {
      if (skifte.huvudgroda) {
        skifte.godsel.forEach((godsel) => {
          if (!godselDict[godsel.benamning]) {
            godselDict[godsel.benamning] = [];
          }

          if (
            !godselDict[godsel.benamning].find(
              (gd) => gd.cropKey === skifte.huvudgroda.benamning
            )
          ) {
            const godselObj = new ExportHSGodsel();
            godselObj.benamning = godsel.benamning;
            godselObj.cropKey = skifte.huvudgroda.benamning;
            godselObj.mangd.push(godsel.giva);
            godselObj.pris.push(godsel.pris);
            godselObj.areal = godsel.areal;
            const totMangd = godsel.giva * godsel.areal;
            godselObj.totMangd.push(totMangd);
            godselObj.totPris.push(totMangd * godsel.pris);

            godselDict[godsel.benamning].push(godselObj);
          } else {
            const existingGodselGroda = godselDict[godsel.benamning].find(
              (gd) => gd.cropKey === skifte.huvudgroda.benamning
            );
            existingGodselGroda.mangd.push(godsel.giva);
            existingGodselGroda.pris.push(godsel.pris);
            const totMangd = godsel.giva * godsel.areal;
            existingGodselGroda.areal += godsel.areal;
            existingGodselGroda.totMangd.push(totMangd);
            existingGodselGroda.totPris.push(totMangd * godsel.pris);
          }
        });
      }
    });

    return godselDict;
  }

  /**
   * Get a dictionary of 'vaxtskydd'-data grouped on crop name
   */
  private getVaxtskyddObject() {
    const vaxtskyddDict = {};
    const filteredSkiften = this.allSkiften.filter(
      (s) => this.selectedSkifteId.indexOf(s.id) >= 0
    );

    filteredSkiften.forEach((skifte) => {
      if (skifte.huvudgroda) {
        skifte.vaxtskyddtillfalle.forEach((tillf) => {
          tillf.vaxtskydd.forEach((vaxtskydd) => {
            if (!vaxtskyddDict[vaxtskydd.benamning]) {
              vaxtskyddDict[vaxtskydd.benamning] = [];
            }

            if (
              !vaxtskyddDict[vaxtskydd.benamning].find(
                (gd) => gd.cropKey === skifte.huvudgroda.benamning
              )
            ) {
              const vaxtskyddObj = new ExportHSVaxtskydd();
              vaxtskyddObj.benamning = vaxtskydd.benamning;
              vaxtskyddObj.cropKey = skifte.huvudgroda.benamning;
              vaxtskyddObj.mangd.push(vaxtskydd.dos);
              vaxtskyddObj.pris.push(vaxtskydd.pris);
              vaxtskyddObj.areal = tillf.areal;
              const totMangd = vaxtskydd.dos * tillf.areal;
              vaxtskyddObj.totMangd.push(totMangd);
              vaxtskyddObj.totPris.push(totMangd * vaxtskydd.pris);

              vaxtskyddDict[vaxtskydd.benamning].push(vaxtskyddObj);
            } else {
              const existingGodselGroda = vaxtskyddDict[
                vaxtskydd.benamning
              ].find((gd) => gd.cropKey === skifte.huvudgroda.benamning);
              existingGodselGroda.mangd.push(vaxtskydd.dos);
              existingGodselGroda.pris.push(vaxtskydd.pris);
              const totMangd = vaxtskydd.dos * tillf.areal;
              existingGodselGroda.areal += tillf.areal;
              existingGodselGroda.totMangd.push(totMangd);
              existingGodselGroda.totPris.push(totMangd * vaxtskydd.pris);
            }
          });
        });
      }
    });

    return vaxtskyddDict;
  }

  /**
   * Get a dictionary of 'utsade'-data grouped on crop name
   */
  private getUtsadeObject() {
    const utsadeDict = {};
    const filteredSkiften = this.allSkiften.filter(
      (s) => this.selectedSkifteId.indexOf(s.id) >= 0
    );

    filteredSkiften.forEach((skifte) => {
      if (skifte.huvudgroda) {
        const tu = this.tabellUtsade.find(
          (t) => t.id === skifte.huvudgroda.utsadeId
        );

        if (!utsadeDict[skifte.huvudgroda.benamning]) {
          const utsadeObj = new ExportHSUtsade();
          utsadeObj.cropKey = skifte.huvudgroda.benamning;
          if (tu) {
            utsadeObj.groda = tu.groda;
            utsadeObj.sort = tu.sort;
          }

          this.addUtsadeData(utsadeObj, skifte, tu);

          utsadeDict[skifte.huvudgroda.benamning] = utsadeObj;
        } else {
          const existingObj = utsadeDict[skifte.huvudgroda.benamning];
          this.addUtsadeData(existingObj, skifte, tu);
        }
      }
    });

    return utsadeDict;
  }

  /**
   * Push 'utsade'-data to arrays
   */
  private addUtsadeData(
    obj: ExportHSUtsade,
    skifte: SkifteDetielsModel,
    tabellUtsade: TabellUtsadeModel
  ) {
    obj.areal += skifte.huvudgroda.areal;
    obj.mangdUtsade.push(skifte.huvudgroda.giva);
    const totMangd = skifte.huvudgroda.giva * skifte.areal;
    obj.totMangdUtsade.push(totMangd);

    if (tabellUtsade) {
      obj.prisUtsade.push(skifte.huvudgroda.pris);
      obj.totPrisUtsade.push(totMangd * skifte.huvudgroda.pris);
    }

    if (skifte.fangGroda) {
      obj.mangdFanggroda.push(skifte.huvudgroda.giva);
      obj.totMangdFanggroda.push(totMangd);
    }

    skifte.skord.forEach((skord) => {
      const utsadeSkord = this.tabellUtsade.find(
        (t) => t.id === skord.utsadeId
      );

      if (utsadeSkord) {
        // Kolla om produkt är halm
        if (utsadeSkord.prodId === 1400 || utsadeSkord.prodId === 2753) {
          obj.prisHalm.push(skord.pris);
          obj.mangdHalm.push(this.getSkordHaTorr(skord));
          const totHalm = this.getSkordHaTorr(skord) * skord.areal;
          obj.totMangdHalm.push(totHalm);
          obj.totPrisHalm.push(totHalm * skord.pris);
        }
      }

      if (skord.benamning === obj.cropKey) {
        obj.mangdSkord.push(this.getSkordHaTorr(skord));
        const totSkord = this.getSkordHaTorr(skord) * skord.areal;
        obj.totMangdSkord.push(totSkord);
        obj.vattenhaltSkord.push(skord.vh);
        if (utsadeSkord) {
          obj.prisSkkord.push(utsadeSkord.prisAvs);
          obj.totPrisSkkord.push(totSkord * skord.pris);
        }
      }
    });
  }

  /**
   * Calculate amount of dryed yield
   */
  private getSkordHaTorr(skord: SkordModel): number {
    if (skord.areal > 0) {
      return Number(
        (
          ((skord.mangd / skord.areal) * (1 - skord.vh / 100)) /
          (1 - skord.vhAvr / 100)
        ).toFixed(0)
      );
    } else {
      return 0;
    }
  }

  export() {}

  goToStart() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.router.navigate(['client', params['clientid']]);
    });
  }
}

export class ExportHSUtsade {
  cropKey: string;
  groda: string;
  sort: string;
  areal = 0;
  mangdUtsade: number[] = [];
  prisUtsade: number[] = [];
  totMangdUtsade: number[] = [];
  totPrisUtsade: number[] = [];
  mangdFanggroda: number[] = [];
  prisFanggroda: number[] = [];
  totMangdFanggroda: number[] = [];
  totPrisFanggroda: number[] = [];
  mangdSkord: number[] = [];
  prisSkkord: number[] = [];
  totMangdSkord: number[] = [];
  totPrisSkkord: number[] = [];
  vattenhaltSkord: number[] = [];
  mangdHalm: number[] = [];
  prisHalm: number[] = [];
  totMangdHalm: number[] = [];
  totPrisHalm: number[] = [];
}

export class ExportHSGodsel {
  cropKey: string;
  benamning: string;
  groda: string;
  areal: number;
  mangd: number[] = [];
  totMangd: number[] = [];
  pris: number[] = [];
  totPris: number[] = [];
}

export class ExportHSVaxtskydd {
  cropKey: string;
  benamning: string;
  groda: string;
  areal: number;
  mangd: number[] = [];
  totMangd: number[] = [];
  pris: number[] = [];
  totPris: number[] = [];
}

export interface Provider {
  fullname: string;
  shortname: string;
  country: Countries;
  image: string;
  route: string;
  color: string;
  category: string;
}

export enum Countries {
  'all',
  'se',
  'no',
  'fi',
  'nl',
  'de',
  'fr'
}

export enum Icons {
  trimble = '/assets/trimble-logo.svg',
  johndeere = '/assets/icon-jd.svg',
  myjohndeere = '/assets/icon-jd.svg',
  yara = '/assets/icon-yara.svg',
  shape = '/assets/icon-shapefile.svg',
  kverneland = '/assets/icon-kverneland.svg',
  scb = '/assets/icon-scb.svg',
  sam = '/assets/icon-saminternet.svg',
  absolut = '/assets/absolut.svg',
  futurefarm = '/assets/FutureFarm@2x.png',
  hushallningssallskapet = '/assets/icon-hs.svg',
  skira = '/assets/SkiraBeta_Logo_grön-vit.svg',
  cropplandata = '/assets/crop.svg',
}

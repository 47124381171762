import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth/auth.guard";
import { FieldExportComponent } from "./field-export/field-export.component";
import { LoginComponent } from "./login/login.component";
import { BlankComponent } from "./receivers/blank.component";
import { FuturefarmCropplanExportComponent } from "./receivers/futurefarm/cropplan-export/futurefarm-cropplan-export.component";
import { FuturefarmComponent } from "./receivers/futurefarm/futurefarm.component";
import { FuturefarmLogmasterExportComponent } from "./receivers/futurefarm/logmaster-export/futurefarm-logmaster-export.component";
import { HushallningssallskapetLonsamGrodaComponent } from "./receivers/hushallningssallskapet/hushallningssallskapet-lonsamgroda/hushallningssallskapet-lonsamgroda.component";
import { JohnDeereTilldelComponent } from "./receivers/john-deere/john-deere-tilldel/john-deere-tilldel.component";
import { JohnDeereComponent } from "./receivers/john-deere/john-deere.component";
import { KvernelandTilldelComponent } from "./receivers/kverneland/kverneland-tilldel/kverneland-tilldel.component";
import { KvernelandComponent } from "./receivers/kverneland/kverneland.component";
import { MyJohnDeereFieldComponent } from "./receivers/my-john-deere/my-john-deere-field/my-john-deere-field.component";
import { MyJohnDeereTilldelComponent } from "./receivers/my-john-deere/my-john-deere-tilldel/my-john-deere-tilldel.component";
import { MyJohnDeereComponent } from "./receivers/my-john-deere/my-john-deere.component";
import { ReceiverSelectionComponent } from "./receivers/receiver-selection.component";
import { SamInternetFieldComponent } from "./receivers/sam-internet/sam-internet-field/sam-internet-field.component";
import { SamInternetComponent } from "./receivers/sam-internet/sam-internet.component";
import { ScbFertilizerComponent } from "./receivers/scb/scb-fertilizer/scb-fertilizer.component";
import { ScbSprayingComponent } from "./receivers/scb/scb-spraying/scb-spraying.component";
import { ScbComponent } from "./receivers/scb/scb.component";
import { ShapeTilldelComponent } from "./receivers/shape/shape-tilldel/shape-tilldel.component";
import { ShapeComponent } from "./receivers/shape/shape.component";
import { TrimbleFieldComponent } from "./receivers/trimble/trimble-field/trimble-field.component";
import { TrimbleTilldelComponent } from "./receivers/trimble/trimble-tilldel/trimble-tilldel.component";
import { TrimbleComponent } from "./receivers/trimble/trimble.component";
import { VodkaExportComponent } from "./receivers/vodka/vodka-export/vodka-export.component";
import { YaraTilldelComponent } from "./receivers/yara/yara-tilldel/yara-tilldel.component";
import { YaraComponent } from "./receivers/yara/yara.component";
import { NgModule } from "@angular/core";
import { CropplanExportComponent } from "./receivers/cropplan-export/cropplan-export.component";

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'client/:clientid',
    component: ReceiverSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'client/:clientid/sam',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: SamInternetComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'field',
        component: SamInternetFieldComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/hushallningssallskapet',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'lonsamgroda',
        component: HushallningssallskapetLonsamGrodaComponent,
        canActivate: [AuthGuard],
      },

      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'lonsamgroda',
      },
    ],
  },
  {
    path: 'client/:clientid/futurefarm',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: FuturefarmComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'logmaster',
        component: FuturefarmLogmasterExportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cropplan',
        component: FuturefarmCropplanExportComponent,
        canActivate: [AuthGuard],
      },

      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/cropplandata',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: CropplanExportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/skira',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: FuturefarmComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'logmaster',
        component: FuturefarmLogmasterExportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'cropplan',
        component: FuturefarmCropplanExportComponent,
        canActivate: [AuthGuard],
      },

      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/shape',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: ShapeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'field',
        component: FieldExportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tilldel',
        component: ShapeTilldelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/kverneland',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: KvernelandComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tilldel',
        component: KvernelandTilldelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/trimble',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: TrimbleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'field',
        component: TrimbleFieldComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tilldel',
        component: TrimbleTilldelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/yara',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: YaraComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tilldel',
        component: YaraTilldelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/johndeere',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: JohnDeereComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tilldel',
        component: JohnDeereTilldelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/myjohndeere',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: MyJohnDeereComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'field',
        component: MyJohnDeereFieldComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tilldel',
        component: MyJohnDeereTilldelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/scb',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'selection',
        component: ScbComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'fertilizer',
        component: ScbFertilizerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'spraying',
        component: ScbSprayingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'selection',
      },
    ],
  },
  {
    path: 'client/:clientid/absolut',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'export',
        component: VodkaExportComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { relativeLinkResolution: 'legacy' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

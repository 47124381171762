import { DvToolbarEnvironment } from "@dv/toolbar-msal";

export const environment: DvToolbarEnvironment = {
  appKey: '26e300bc-8f7a-45ca-b107-070112126bc6',
  appName: 'export',
  defaultLanguage: 'sv',
  dvApiUrl: 'https://api.datavaxt.se/api/v1/',
  iframeProxyUrl: 'https://toolbar.datavaxt.se',
  production: true,
  redirectUri: 'https://export.datavaxt.se/',
};

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { SkifteDetielsModel, BrukningsenhetModel } from '../../models/Models';
import { ClientService } from '../../client.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dv-skifte',
  templateUrl: './skifte.component.html',
  styleUrls: ['./skifte.component.scss'],
})
export class SkifteComponent implements OnInit {
  private _year = 0;
  private _brukenheter: BrukningsenhetModel[] = [];
  loaded = false;
  displayedColumns = [
    'select',
    'brukEnhNamn',
    'namn',
    'eu',
    'huvudgrodaBenamning',
    'areal',
  ];
  skiften: SkifteDetielsModel[] = [];
  skiftenDatasource = new MatTableDataSource<SkifteDetielsModel>(this.skiften);
  selection = new SelectionModel<SkifteDetielsModel>(true, []);

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService
  ) {}

  @Output() selectedSkiften = new EventEmitter();

  @Input()
  set brukenheter(brukenheter: BrukningsenhetModel[]) {
    this._brukenheter = brukenheter;
    if (this._year > 0 && brukenheter.length > 0) {
      this.loadData();
    }
  }

  @Input() eu: boolean;

  @Input()
  set year(year: number) {
    this._year = year;
    if (year > 0) {
      this.loadData();
    }
  }

  loadData() {
    this.loaded = false;
    this.skiftenDatasource = new MatTableDataSource<SkifteDetielsModel>(
      this.skiften
    );

    this.selection.clear();

    this.activatedRoute.pathFromRoot[1].params.subscribe((p) => {
      this.clientService.getSkiften(this._year).subscribe((skiften) => {
        if (this.eu) {
          this.skiftenDatasource = new MatTableDataSource<SkifteDetielsModel>(
            skiften.filter(
              (s) =>
                this._brukenheter.find((b) => b.id === s.brukEnhId) && s.ingarEU
            )
          );
        } else {
          this.skiftenDatasource = new MatTableDataSource<SkifteDetielsModel>(
            skiften.filter((s) =>
              this._brukenheter.find((b) => b.id === s.brukEnhId)
            )
          );
        }

        this.skiftenDatasource.data.forEach((row) =>
          this.selection.select(row)
        );
        this.loaded = true;
      });
    });
  }

  ngOnInit() {
    this.selection.changed.subscribe((res) => {
      this.selectedSkiften.emit(this.selection.selected.map((s) => s.id));
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.skiftenDatasource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.skiftenDatasource.data.forEach((row) =>
          this.selection.select(row)
        );
  }
}

import { Component, OnInit } from '@angular/core';
import { BrukningsenhetModel, SCBClientInput } from '../../../models/Models';
import { ClientService } from '../../../client.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';

@Component({
  selector: 'dv-scb-spraying',
  templateUrl: './scb-spraying.component.html',
  styleUrls: ['./scb-spraying.component.scss'],
})
export class ScbSprayingComponent implements OnInit {
  currStepp: number = undefined;
  selectedBrukningsenheter: BrukningsenhetModel[] = [];
  selectedSkifteId: number[] = [];
  year = 0;
  org: string;

  constructor(
    private clientService: ClientService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }

  ngOnInit() {}
  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
  }

  selectedYear(ev) {
    this.year = ev;
  }

  export() {
    const input: SCBClientInput = <SCBClientInput>{};
    input.skifteId = this.selectedSkifteId;
    input.orgnr = this.org;

    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.exportScb(input, this.year, 'vaxtskydd'),
      disableClose: true,
    });
  }

  goToStart() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.router.navigate(['client', params['clientid']]);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DvClientService, DvUserService } from '@dv/toolbar-msal';
import { take } from 'rxjs';
import { UnauthorizedComponent } from './common-components/unauthorized/unauthorized.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private dvClientService: DvClientService,
    private dvUserService: DvUserService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dvClientService.client$.pipe(take(1)).subscribe(client => {
      if (!client) {
        return;
      }

      if (!client.id) {
        this.dvUserService.openChangeClientDialog().afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['client', result.clientId])
          }
        });
      }

      if (!client.rattigheter?.exportData) {
        this.dialog
          .open(UnauthorizedComponent)
          .afterClosed()
          .subscribe(() => { });
      }

      this.router.navigate(['client', client.id])
    });
  }
}

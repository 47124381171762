import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatrialModule } from '../matrial.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WizardComponent } from './wizard-component/WizardComponent';
import { WizardStepComponent } from './wizard-component/WizardStepComponent';
import { BrukenhetComponent } from './brukenhet/brukenhet.component';
import { DoneDialogComponent } from './done-dialog/done-dialog.component';
import { DownloadFileStatusComponent } from './download-file-status/download-file-status.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { GivaComponent } from './giva/giva.component';
import { SelectFileComponent } from './select-file/select-file.component';
import { DvMapComponent } from './map/dv-map.component';
import { DvMapLegendComponent } from './map-legend/dv-map-legend.component';
import { DvAlertComponent } from './alert/dv-alert.component';
import { FormsModule } from '@angular/forms';
import { YearComponent } from './year/year.component';
import { SkifteComponent } from './skifte/skifte.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LogmasterMachineComponent } from './logmaster-machine/logmaster-machine.component';
import { MatSortModule } from '@angular/material/sort';
import { OperationDoneDialogComponent } from './operation-done-dialog/operation-done-dialog.component';
import { DvTranslatePipe } from '@dv/toolbar-msal';

@NgModule({
  imports: [
    CommonModule,
    MatrialModule,
    FlexLayoutModule,
    FormsModule,
    MatSortModule,
    DvTranslatePipe,
  ],
  declarations: [
    WizardComponent,
    WizardStepComponent,
    BrukenhetComponent,
    DoneDialogComponent,
    OperationDoneDialogComponent,
    DownloadFileStatusComponent,
    FilePreviewComponent,
    GivaComponent,
    SelectFileComponent,
    DvMapComponent,
    DvMapLegendComponent,
    DvAlertComponent,
    YearComponent,
    SkifteComponent,
    UnauthorizedComponent,
    LogmasterMachineComponent,
  ],
  exports: [
    WizardComponent,
    WizardStepComponent,
    BrukenhetComponent,
    DoneDialogComponent,
    OperationDoneDialogComponent,
    DownloadFileStatusComponent,
    FilePreviewComponent,
    GivaComponent,
    SelectFileComponent,
    DvMapComponent,
    DvMapLegendComponent,
    DvAlertComponent,
    YearComponent,
    SkifteComponent,
    UnauthorizedComponent,
    LogmasterMachineComponent,
  ],
})
export class CommmonComponentModule {}

import { ExportType } from '../models/ExportTypes';

export abstract class BaseReceiver {
  skiften = false;
  tilldelning = false;
  scb = false;
  futurefarm = false;

  constructor(exportType: ExportType) {
    if (exportType & ExportType.Skiften) {
      this.skiften = true;
    }

    if (exportType & ExportType.Tilldelning) {
      this.tilldelning = true;
    }

    if (exportType & ExportType.Scb) {
      this.scb = true;
    }

    if (exportType & ExportType.Futurefarm) {
      this.futurefarm = true;
    }
  }
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BrukningsenhetModel, SAMExportInput } from '../../../models/Models';
import { ClientService } from '../../../client.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';

@Component({
  selector: 'dv-sam-internet-field',
  templateUrl: './sam-internet-field.component.html',
  styleUrls: ['./sam-internet-field.component.scss'],
})
export class SamInternetFieldComponent implements OnInit {
  currStepp: number = undefined;
  selectedBrukningsenheter: BrukningsenhetModel[] = [];
  selectedSkifteId: number[] = [];
  year = 0;
  kundb: string;
  kunds: string;
  org: string;
  adId = '00';
  inklArea = true;

  constructor(
    private clientService: ClientService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.clientService.setClient(params['clientid']);
    });
  }

  ngOnInit() {
    this.clientService.getGrunddata().subscribe((grunddata) => {
      const numberPattern = /\d+/g;
      const charPattern = /\D+/g;

      const matchNum = grunddata.kNrLansStyr.match(charPattern);
      const matchChar = grunddata.kNrLansStyr.match(numberPattern);

      if (matchNum && matchChar) {
        this.kundb = matchNum[0];
        this.kunds = matchChar[0];
      } else {
        this.kunds = grunddata.kNrLansStyr;
      }

      this.org = grunddata.orgNr;
    });
  }

  selectedBruk(ev) {
    this.selectedBrukningsenheter = ev;
    this.cd.detectChanges();
  }

  selectedYear(ev) {
    this.year = ev;
  }

  export() {
    const input: SAMExportInput = <SAMExportInput>{};
    input.skifteId = this.selectedSkifteId;
    input.adId = this.adId;
    input.inkluderaYtor = this.inklArea;
    input.kundBokstav = this.kundb;
    input.kundnummer = this.kunds;
    input.organisationsnummer = this.org;

    const dialogRef = this.dialog.open(DoneDialogComponent, {
      data: this.clientService.exportSam(input, this.year),
      disableClose: true,
    });
  }

  goToStart() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.router.navigate(['client', params['clientid']]);
    });
  }
}

<mat-card>
  <mat-card-title>
    {{ "_common_adjust_file" | dvt }}
    <button mat-icon-button (click)="show = false" style="float: right">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content>
    <p>{{ "_common_adjust_concentration" | dvt }}</p>
    <mat-form-field>
      <input matInput placeholder="{{ '_common_concentration' | dvt }}" [(ngModel)]="naring" type="number" step="1" />
      <span matSuffix>%</span>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        placeholder="{{ '_common_total_consumption' | dvt }}"
        [(ngModel)]="total"
        type="number"
        step="1"
      />
      <span matSuffix>{{ "_common_unit" | dvt }}</span>
    </mat-form-field>
    <div>
      <button mat-raised-button (click)="update()">{{ "_common_update" | dvt }}</button>
    </div>
  </mat-card-content>
</mat-card>

<div class="container">
  <div class="intro-header">{{ "_export_select_integration" | dvt }}</div>
  <div class="intro-text">{{ "_export_click_integration" | dvt }}</div>

  <div class="wrapper">
    <!-- Geografic -->
    <div class="integration-header">
      {{ "Geografisk data" | dvt }}
    </div>

    <div class="integration-wrapper">
      <div
        class="integration-card"
        *ngFor="let integration of integrations | list: 'category':'geo'"
        (click)="initIntegration(integration.shortname)"
        [routerLink]="integration.route"
      >
        <div matRipple class="integration-icon" [style.background-color]="integration.color">
          <img *ngIf="integration.image" [src]="integration.image" [alt]="integration.fullname" />
        </div>
        <div class="integration-text">
          {{ integration.fullname | dvt }}
        </div>
      </div>
    </div>

    <!-- Information -->
    <div class="integration-header">
      {{ "Information" | dvt }}
    </div>

    <div class="integration-wrapper">
      <div
        class="integration-card"
        *ngFor="let integration of integrations | list: 'category':'info'"
        [routerLink]="integration.route"
      >
        <div matRipple class="integration-icon" [style.background-color]="integration.color">
          <img *ngIf="integration.image" [src]="integration.image" [alt]="integration.fullname" />
        </div>
        <div class="integration-text">
          <span>{{ integration.fullname | dvt }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ClientService } from './client.service';
import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
/// Intercom is a chat client that the support uses to help the users
declare var Intercom: (event: string, data?: any) => void;

@Injectable()
export class IntercomService {
  private lastUserId: number = null;

  constructor(private clientService: ClientService, private route: Router) {
    combineLatest(
      route.events.pipe(filter((r) => r instanceof NavigationEnd)),
      clientService.getUser()
    ).subscribe((res) => {
      const user = res[1];
      if (Intercom !== undefined) {
        if (user.anvId !== this.lastUserId) {
          Intercom('boot', {
            APP_ID: 'd5lio49d',
            email: user.epost,
            user_id: user.anvId,
            niva: user.radgivare ? 'Rådgivare' : 'Användare',
            name: user.namn || user.epost,
            user_hash: user.intercomHash,
            lang: user.languageCode,
          });

          this.lastUserId = user.anvId;
        } else {
          Intercom('update');
        }
      }
    });
  }
}

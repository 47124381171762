import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { LayerService } from '../../layer.service';
import { UserFileModel } from '../../models/FileModel';

@Component({
  selector: 'dv-giva',
  templateUrl: './giva.component.html',
  styleUrls: ['./giva.component.scss'],
})
export class GivaComponent implements OnInit {
  constructor(private layerService: LayerService) {}

  naring: number;
  total: number;
  currentFile: UserFileModel;

  @HostBinding('class.active') public show = false;
  @Input() set file(value: UserFileModel) {
    if (value) {
      this.naring = value.naring;
      this.total = value.legend.sum;
    } else {
      this.naring = 0;
      this.total = 0;
    }
    this.currentFile = value;
  }

  @Output() givaChanged: EventEmitter<void> = new EventEmitter<void>();

  update() {
    this.file = this.layerService.setNewTotal(
      this.currentFile,
      this.total,
      this.naring
    );

    this.show = false;
    this.givaChanged.emit();
  }

  ngOnInit() {}
}

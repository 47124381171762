import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';
import { BrukningsenhetModel } from '../../models/Models';
import { MatSelectionList } from '@angular/material/list';
import { DvToolbarTranslateService } from '@dv/toolbar-msal';

@Component({
  selector: 'dv-brukenhet',
  templateUrl: './brukenhet.component.html',
  styleUrls: ['./brukenhet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BrukenhetComponent implements OnInit {
  @ViewChild('selectedBruk', { static: true })
  private selectedBrukList: MatSelectionList;

  @Output() selectedBruk = new EventEmitter();

  constructor(
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translateService: DvToolbarTranslateService,
    private cd: ChangeDetectorRef
  ) {}

  bruk: BrukningsenhetModel[] = null;

  ngOnInit() {
    this.activatedRoute.pathFromRoot[1].params.subscribe(
      (p) => {
        this.clientService.getBruk(p['clientid']).subscribe((bruk) => {
          this.bruk = [...bruk];
          this.cd.detectChanges();
        });
      },
      (err) => {
        this.snackBar.open(err, this.translateService.t('Stäng'));
      }
    );

    this.selectedBrukList.selectedOptions.changed.subscribe((ev) => {
      this.selectedBruk.next(
        this.selectedBrukList.selectedOptions.selected.map((s) => s.value)
      );
      this.cd.detectChanges();
    });
  }

  toggleSelect(state) {
    if (state) {
      this.selectedBrukList.selectAll();
    } else {
      this.selectedBrukList.deselectAll();
    }
  }
}

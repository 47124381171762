import { ActivatedRoute, Router } from '@angular/router';

export class Receiver {
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {}

  goToStart() {
    this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
      this.router.navigate(['client', params['clientid']]);
    });
  }
}

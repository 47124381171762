<mat-icon style="top: -110px" color="warn" class="size-128" *ngIf="errorRes !== null">error</mat-icon>
<mat-icon color="accent" class="size-128" *ngIf="fileRes !== null">check_circle</mat-icon>
<mat-spinner style="margin-bottom: 15px" *ngIf="fileRes === null && errorRes === null"></mat-spinner>

<p *ngIf="fileRes === null && errorRes === null">
  {{ "_common_processing_your_files" | dvt }}
</p>

<p *ngIf="errorRes">{{ "_common_internal_error_code" | dvt }}: {{ errorRes.exceptionResponse.errorCode }}</p>
<p *ngIf="errorRes">{{ "_common_message" | dvt }}: {{ errorRes.exceptionResponse.message }}</p>
<p *ngIf="errorRes">{{ "_common_description" | dvt }}: {{ errorRes.exceptionResponse.description }}</p>
<p *ngIf="errorRes">{{ "_common_status" | dvt }}: {{ errorRes.statusCode }}</p>

<mat-dialog-content *ngIf="fileRes">
  <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 15px">
    <img src="{{ fileRes.icon }}" style="margin-right: 15px; width: 75px" alt="" />
    <span
      >{{ fileRes.msg }}
      <a href="{{ fileRes.uri }}" style="margin-bottom: 5px" *ngIf="fileRes.uri">{{ "_common_here" | dvt }}</a></span
    >
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="fileRes || errorRes">
  <span style="flex: auto"></span>
  <button mat-raised-button mat-dialog-close color="accent">
    {{ "_common_done" | dvt }}
  </button>
</mat-dialog-actions>

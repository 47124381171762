import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { ClientService, Upload } from '../../../client.service';
import { DoneDialogComponent } from '../../../common-components/done-dialog/done-dialog.component';
import { BrukningsenhetModel } from '../../../models/Models';

@Component({
    selector: 'dv-my-john-deere-field',
    templateUrl: './my-john-deere-field.component.html',
    styleUrls: ['./my-john-deere-field.component.scss'],
})
export class MyJohnDeereFieldComponent implements OnInit {
    currStepp: number = undefined;
    selectedBrukningsenheter: BrukningsenhetModel[] = [];

    constructor(
        private clientService: ClientService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
            this.clientService.setClient(params['clientid']);
        });
    }
    ngOnInit() {}

    selectedBruk(ev) {
        this.selectedBrukningsenheter = ev;
        this.cd.detectChanges();
    }

    export(providerShortName: string) {
        this.dialog.open(DoneDialogComponent, {
            data: this.clientService.getLastArForKlient().pipe(
                mergeMap((year) =>
                    this.clientService.uploadData(
                        providerShortName,
                        'upload',
                        Upload.field,
                        {
                            year: year,
                            farmIds: this.selectedBrukningsenheter.map(
                                (b) => b.id
                            ),
                        }
                    )
                )
            ),
            disableClose: true,
        });
    }

    goToStart() {
        this.activatedRoute.pathFromRoot[1].params.subscribe((params) => {
            this.router.navigate(['client', params['clientid']]);
        });
    }
}
